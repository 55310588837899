export const timestampToDate = (timestamp, type=1) => {
    const date = new Date(timestamp * 1000); //将时间戳乘以1000，转换为JavaScript中的毫秒值
    const year = date.getFullYear();
    const month = date.getMonth() + 1; //月份是从0开始计算的，需要加1
    const day = date.getDate();
    const hour = date.getHours();
    const minute = date.getMinutes();
    const second = date.getSeconds();
    let str = '';
    if(type == 1){
        str =  year + "-" + month + "-" + day
    }else if(type == 2){
        str = year + "-" + month + "-" + day + " " + hour + ":" + minute + ":" + second;
    }
    return str;
    
}