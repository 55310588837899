<template>
    <div>
      <van-nav-bar :title="title"  left-arrow @click-left="onClickLeft"/>
          
      <div class="form_con">
        <div class="top_tips">
            <span>捐赠者资料填写</span>
        </div>
        
        <van-form ref="postForm" @submit="onSubmit">
            <van-field
            v-model="book_donation"
            name="__diyname__"
            rows="1"
            type="hidden"/>
            <van-cell-group inset>
                <van-cell>
                    <van-field
                v-model="username"
                name="row[name]"
                label="姓名"
                placeholder="姓名"
                :rules="[{ required: true, message: '请填写姓名' }]"
                />
                </van-cell>
                <van-cell>
                    <van-field
                    v-model="phone"
                    type="tel"
                    name="row[phone]"
                    label="电话号码"
                    placeholder="电话号码"
                    :rules="[{ required: true, message: '请填写电话号码' }]"
                    />
                    <van-field
                    v-model="code"
                    type="number"
                    name="row[captcha]"
                    label=""
                    placeholder="请输入手机验证码"
                    :rules="[{ required: true, message: '请输入手机验证码' }]"
                    >
                    <template #button>
                        <van-button size="small" @click="sendSms()" type="danger">发送验证码</van-button>
                    </template>
                    </van-field>
                </van-cell>
                <van-cell>
                    <van-field
                v-model="email"
                name="row[email]"
                type="email"    
                label="电子邮箱"
                placeholder="请输入邮箱"
                :rules="[{ required: true, message: '请输入邮箱' }]"
                />
                </van-cell>
                <van-cell>
                    <van-field
                v-model="address"
                name="row[address]"
                type="text"    
                label="联系地址"
                placeholder="请输入联系地址"
                :rules="[{ required: true, message: '请输入联系地址' }]"
                />
                </van-cell>
                <van-cell title="是否公开">
                    <van-radio-group v-model="is_open" direction="horizontal">
                        <van-radio name="yes">是</van-radio>
                        <van-radio name="no">否</van-radio>
                    </van-radio-group>
                </van-cell>
                <van-cell>
                    <van-field
                v-model="books"
                name="row[books]"
                type="text"    
                label="捐赠内容"
                placeholder="请输入捐赠内容"
                :rules="[{ required: true, message: '请输入捐赠内容' }]"
                />
                </van-cell>
                <van-cell>
                    <van-field
                        v-model="info"
                        rows="3"
                        name="row[conn]"
                        autosize
                        label="简要介绍"
                        type="textarea"
                        placeholder="请输入简要介绍"
                        :rules="[{ required: true, message: '请输入简要介绍' }]"
                    />
                </van-cell>
            </van-cell-group>
            <div style="margin: 16px;">
                <van-button round block type="danger" native-type="submit">
                提交
                </van-button>
            </div>
            </van-form>
      </div>
    </div>
</template>
<script>
import {postbook, sendSmsRequest} from '@/api/archives'
import { showDialog } from 'vant';
import { showToast } from 'vant';
import { ref } from 'vue';
    export default {
        name: 'BookDonate',
        data(){
            return {
                title: '征集募捐',
                show_result: false
            }
        },
        created(){
            document.title = this.title;
            const descriptionEle = document.querySelector('meta[name="description"]');
            if (descriptionEle) {
                descriptionEle.content = this.title;
            }
        },
        setup() {
            const book_donation = ref('book_donation');
            const username = ref('');
            const phone = ref('');
            const code = ref('');
            const email = ref('');
            const address = ref('');
            const is_open = ref('');
            const books = ref('');
            const info = ref('');
            const onSubmit = (values) => {
                
                values.is_open =is_open.value
                postbook(values).then((data) => {
                   
                    if(data.data.code == 1){
                        showDialog({
                            title: '提交成功',
                            message: data.data.msg,
                            }).then(() => {
                            // on close
                            });
                    }
                });
                console.log(values)
            };
            const sendSms = ()=>{
                
                if(phone.value == ''){
                    showToast('请输入手机号');
                    return false;
                }
                let params = {
                    mobile: phone.value,
                    event: 'donation'
                }
                sendSmsRequest(params).then((data)=>{
                    showToast(data.data.msg)
                });
            }

            return {
                book_donation,
            username,
            phone,
            code,
            email,
            address,
            is_open,
            info,
            books,
            sendSms,    
            onSubmit,
            };
        },
        methods:{
            onClickLeft(){
                history.back();
            },
            onClickRight(){
                this.$router.push('/book/donateList');
            },
        }

    }
</script>
<style scoped>
.form_con{
    margin-top: 5px;
}
.top_tips{
    text-align: left;
    font-size: 13px;
    font-weight: 700;
    margin-left: 25px;
}
:deep(.van-field__label:before) {
    content: '*';
    display: inline-block;
    margin-right: 4px;
    color: #f00;
  }
</style>
