<template>
    <div>
      <van-nav-bar :title="title"  left-arrow @click-left="onClickLeft"/>
      <div>
        <van-search
        v-model="kw"
        show-action
        placeholder="请输入搜索关键词"
        @search="onSearch"
      >
        <template #action>
          <div @click="onClickButton">搜索</div>
        </template>
      </van-search>
      </div>
      <div>
          <van-tabs  @click-tab="onClickTab" v-model:active="defaultChannel">
              <van-tab v-for="item in channelList"  :title="item.channel_name" :name="item.channel_id" :key="item.channel_id">{{  }}</van-tab> 
          </van-tabs>
      </div>
      <div>
        <van-list  v-model:loading="loading"  :finished="finished"  finished-text="没有更多了"
    @load="getData">
            <div v-for="item in list" :key="item.id" class="part1" @click="toplay(item)">
                <van-image class="" width="95%"  :src="item.image"/>
<div class="p2">{{ item.title }}</div>
            </div>
            
          </van-list>
      </div>
    </div>
  </template>
  <script>
  // import { ref } from 'vue';
  import {getArchiveList} from '@/api/archives'
 
  import {timestampToDate} from '@/utils/tools'
  export default {
    name: 'ListPage',
    data(){
      return {
        list: [],
        loading: false,
        finished: false,
        kw: '',
        defaultChannel: 10,
        channelList: [
            {'channel_id': 10, 'channel_name': '红色村落故事'},
            {'channel_id': 20, 'channel_name': '深圳故事'},
            {'channel_id': 60, 'channel_name': '深圳名村'},
            {'channel_id': 50, 'channel_name': '深圳非遗'},
            {'channel_id': 70, 'channel_name': '深圳解放'},
            {'channel_id': 30, 'channel_name': '廉洁文化'},
            {'channel_id': 40, 'channel_name': '其他'},
        ]
      }
    },
    setup(){
        
    },
    computed: {
       
        title(){
          return '影像深圳-视频'
        },
        show_type(){
          return this.$route.query.type??'text'
        }
    },
    mounted(){
      document.title = this.title;
      const descriptionEle = document.querySelector('meta[name="description"]');
      if (descriptionEle) {
        descriptionEle.content = this.title;
      }
    },
    methods:{
      onClickButton(){
        this.getData();
      },
      toplay(item){
        this.$router.push({path: '/site/player', query:{id: item.id}});
      },
      onClickLeft(){
        history.back();
      },
      formatTime(time){
        return timestampToDate(time);
      },
      onClickTab(index, title)  {
          console.log(index, title)
          console.log(this.defaultChannel)
          this.list = [];
          this.page = 1;
          this.current_page = 1;
          this.finished = false;
          // this.getData();
      },
      onSearch(){
          this.page = 1;
          this.list = [];
          this.getData();
      },
      getData(){
        let params = {
          page: 1,
          size: 15,
          diyname: 'video',
          type: this.$route.query.type,
          kw: this.kw,
          ssd: this.defaultChannel
        }
        getArchiveList(params).then((data)=>{
                let info = data.data.data.archivesList
                this.list = info.data;
                this.loading = false;
                this.finished = true; // 全部加载完
                // this.content = info.content;
                // this.news_title = info.title;
                // this.source = info.user.username;
                // this.time = timestampToDate(info.createtime)
        });
      }
    }
  }
  </script>
  <style scoped>
  .van-nav-bar{
     background-color: #e04242;
   }

   :deep(.van-nav-bar__title){
     --van-nav-bar-title-text-color: #fff;
     --van-nav-bar-title-font-size: 16px;
     --van-font-bold: 400;
   }
   :deep(.van-icon-arrow-left){
     color:antiquewhite;
   }

   :deep(.van-cell__title){
    text-align: left;
   }
   :deep(.van-image__img){
    border-radius: 3px;
   }
   .part1{
    margin:5%;

   }
   .p2{
    text-align: left;
    font-size: 12px;
    margin-left: 3%;
   }
   </style>