<template>
    <div>
        <van-nav-bar :title="title"  left-arrow @click-left="onClickLeft"/>
        <div class="con">
            <div>
                <van-image
                        :src="require('../../assets/subject/chairman_topical.png')"
                        />
            </div>
<!--ds-->
            <div class="part1_1">
                <div class="title1">
                    <van-image class="party" :src="require('../../assets/subject/chairman_party.png')"/>
                    <span>习近平论党史</span>
                </div>
            </div>
            <div class="part2_1">
                <van-image :src="img_ds"/>
                <div class="part2_2">
                    <p class="desc" v-html="ds_desc"></p>
                </div>
            </div>
            <div class="part3">
                <div class="part3_1 ds_bg">
                    <div class="sub_con">
                        <div class="sub_con_1">习近平论党史</div>
                        <div class="sub_con_2">
                            <van-swipe indicator-color="#df6a74">
                                <van-swipe-item>
                                <ul>
                                    <li v-for="(item, index) in ds_list" v-bind:key="item.id">
                                        <template v-if="index<3">
                                        <div class="item_1">
                                            <van-image :src="require('../../assets/subject/start.png')"/>
                                            {{ item.description }}
                                        </div>
                                        <div class="item_2">{{ item.title }}</div>
                                    </template>
                                    </li>
                                </ul>
                                </van-swipe-item>
                                <van-swipe-item>
                                    <ul>
                                    <li v-for="(item, index) in ds_list" v-bind:key="item.id">
                                        <template v-if="index>2">
                                        <div class="item_1">
                                            <van-image :src="require('../../assets/subject/start.png')"/>
                                            {{ item.description }}
                                        </div>
                                        <div class="item_2">{{ item.title }}</div>
                                    </template>
                                    </li>
                                </ul>
                                </van-swipe-item>

                            </van-swipe>
                        </div>
                    </div>
                    <div>
                        <div class="part3_1_1">
                            <div class="button1">
                                <span @click="more('xjpldsgz')">查看更多</span>
                            </div>
                        </div>

                    </div>                

                </div>
            </div>
<!--end ds-->
<!--dfz-->
            <div class="part1_1">
                <div class="title1">
                    <van-image class="party1" :src="require('../../assets/subject/chairman_party.png')"/>
                    <span>习近平论地方志工作</span>
                </div>
            </div>
            <div class="part2_1">
                <van-image :src="img_dfz"/>
                <div class="part2_2">
                    <p class="desc" v-html="dfz_desc"></p>
                </div>
            </div>
            <div class="part3">
                <div class="part3_1 dfz_bg">
                    <div class="sub_con">
                        <div class="sub_con_1">习近平论地方志工作</div>
                        <div class="sub_con_2">
                            <van-swipe indicator-color="#df6a74">
                                <van-swipe-item>
                                <ul>
                                    <li v-for="(item, index) in dfz_list" v-bind:key="item.id">
                                        <template v-if="index<3">
                                        <div class="item_1">
                                            <van-image :src="require('../../assets/subject/start.png')"/>
                                            {{ item.description }}
                                        </div>
                                        <div class="item_2">{{ item.title }}</div>
                                    </template>
                                    </li>
                                </ul>
                                </van-swipe-item>
                                <van-swipe-item>
                                    <ul>
                                    <li v-for="(item, index) in dfz_list" v-bind:key="item.id">
                                        <template v-if="index>2">
                                        <div class="item_1">
                                            <van-image :src="require('../../assets/subject/start.png')"/>
                                            {{ item.description }}
                                        </div>
                                        <div class="item_2">{{ item.title }}</div>
                                    </template>
                                    </li>
                                </ul>
                                </van-swipe-item>

                            </van-swipe>
                        </div>
                    </div>
                    <div>
                        <div class="part3_1_1">
                            <div class="button1">
                                <span @click="more('xjpldfgz')">查看更多</span>
                            </div>
                        </div>

                    </div>                

                </div>
            </div>
<!--end dfz-->
        </div>
    </div>
</template>
<script>
import {xjpldsdfz} from '@/api/archives'
import {timestampToDate} from '@/utils/tools'
export default {
    name: "SznianjianPage",
    
    data(){
        return {
            title: '习近平论党史和地方志工作',
            img_ds: '',
            ds_desc: '',
            ds_list: [],
            img_dfz: '',
            dfz_desc: '',
            dfz_list: [],
        }
    },
    created() {
        
    },
    computed: {
    },
    mounted(){
        document.title = this.title;
        const descriptionEle = document.querySelector('meta[name="description"]');
        if (descriptionEle) {
            descriptionEle.content = this.title;
        }

        this.category = this.$route.query.category;
        this.model = this.$route.query.model;
        this.loadData();
    },
    methods:{
        onClickLeft(){
            history.back();
        },
        formatTime(time){
            return timestampToDate(time);
        },
        more(type){
            let title = '';
            if(type =='xjpldfgz'){
                 title = '习近平论地方志工作';
            }else{
                 title = '习近平论党史';
            }
            this.$router.push({path: '/main/subject/chairman/list', query:{type: type, title: title}});
        },
        loadData(){
            let params ={
            }
            xjpldsdfz(params).then((data)=>{

                    // this.channelList = data.data.data.search_channel;
                    // this.defaultChannel = this.channelList[0].channel_id;
                    // this.bookList = data.data.data.list.data;
                    let info = data.data.data.data;
                    this.img_ds = info.xjpldsgz_image
                    this.ds_desc = info.xjpldsgz_top.description;
                    this.ds_list = info.xjpldsgz;
                    this.img_dfz = info.xjpldfgz_image;
                    this.dfz_desc = info.xjpldfgz_top.description;
                    this.dfz_list = info.xjpldfgz;

                    // this.bookList = info.data;
                    // this.total = info.total;
                    // this.current_page = info.current_page;
                    // this.last_page = info.last_page;
                    //console.log(info)
            });
        }
    }
}
</script>
<style scoped>
   :deep(.van-nav-bar) {
    --van-nav-bar-background: #e04242;
   }
      
   :deep(.van-nav-bar__title){
     --van-nav-bar-title-text-color: #fff;
     --van-nav-bar-title-font-size: 16px;
     --van-font-bold: 400;
   }
   :deep(.van-icon-arrow-left) {
     color:antiquewhite;
   }
   .con{
    width: 100%;
    text-align: center;
   }
   .part1_1{
    position: relative;
    width: 94%;
    margin: auto;
    margin-top: 27px;
   }
   .part2_1{
    
    width: 94%;
    margin: auto;
    margin-top: 15px;
    background-image: url(../../assets/subject/chairman_title_bg.png);
   }
   .part2_2{
    text-align: center;
   }
   .party{
    position: absolute;
    top: -22px;
    left: 27%;
   }
   .party1{
    position: absolute;
    top: -22px;
    left: 20%;
   }
   .title1 span{
    background-color: #b60112;
    color: white;
    padding:5px 15px 5px 50px;
    border-radius: 5px;
   }
   .desc{
    text-indent: 2em;
    width: 94%;
    margin-left: 3%;
    font-size: 14px;
    color: #df6a74;
    text-align: left;
    padding: 3px 5px;
    padding-bottom: 5%;;
   }
   .part3{
    background-color: #f5e6e1;
   }
   .part3_1{
    padding-top: 6%;
    
    background-position: bottom;
    background-repeat: no-repeat;
   }
   .ds_bg{
    background-image: url(../../assets/subject/chairman_party_bg.png);
   }
   .dfz_bg{
    background-image: url(../../assets/subject/chairman_local.png);
   }
   .sub_con{
    background-color: #fff;
    width: 88%;
    margin-left: 3%;
    text-align: left;
    padding: 3%;
    padding-bottom: 5%;
   }
   .sub_con .sub_con_1{
    color: red;
    padding-bottom: 3%;
   }
   .sub_con .sub_con_2{
    font-size: 13px;
   }
   .item_1{
    text-indent: 2em;
   }
   .item_2{
    text-align: right;
    color: #777777;
    margin-top: 2%;
    margin-bottom: 3%;
   }
   :deep(.van-swipe__indicator){
    background-color: black;
    width: 10px;
    height: 10px;
    
   }
   ul{
    padding-bottom: 20px;
   }
   :deep(.van-swipe__indicator--active){
    background-color: #b60112;
   }
   .part3_1_1{
    padding-top: 30px;
    padding-bottom: 30px;
   }
   .button1 span{
    background-color: #b60112;
    color: white;
    padding:5px 25px 5px 25px;
    border-radius: 1px;
    font-size: 13px;
   }
</style>