<template>
    <div>
      <van-nav-bar :title="title"  left-arrow @click-left="onClickLeft"/>
          
      <div class="con">
        <div class="part1">
            <div class="title1">
                <van-image class="party" :src="require('../../../assets/subject/chairman_party.png')"/>
                <span>{{title}}</span>
            </div>
        </div>
        <div class="list">
            <van-list  v-model:loading="loading"  :finished="finished"  finished-text="没有更多了"
    @load="getData">
    <van-cell v-for="item in list" :key="item.id">
    <template #default>
        <div>
            <div class="item_1">
                <van-image :src="require('../../../assets/subject/start.png')"/>
                {{ item.description }}
            </div>
            <div class="item_2">{{ item.title }}</div>
        </div>
    </template>
    </van-cell>
        </van-list>
        </div>
        

      </div>
    </div>
  </template>
  <script>
  // import { ref } from 'vue';
  import {getArchiveList} from '@/api/archives'
  
  import {timestampToDate} from '@/utils/tools'
  export default {
    name: 'ListPage',
    data(){
      return {
        list: [],
        loading: false,
        finished: false,
      }
    },
    setup(){
        
    },
    computed: {
       
        title(){
          return this.$route.query.title
        },
        type(){
          return this.$route.query.type??''
        }
    },
    mounted(){
      // this.getData();
      //console.log(this.type)
    },
    methods:{
      onClickLeft(){
        history.back();
      },
      formatTime(time){
        return timestampToDate(time);
      },
      getData(){
        let params = {
          page: 1,
          size: 15,
          diyname: this.$route.query.type
        }
        getArchiveList(params).then((data)=>{
                let info = data.data.data.archivesList
                this.list = info.data;
                this.loading = false;
                this.finished = true; // 全部加载完
                // this.content = info.content;
                // this.news_title = info.title;
                // this.source = info.user.username;
                // this.time = timestampToDate(info.createtime)
        });
      }
    }
  }
  </script>
  <style scoped>
  :deep(.van-nav-bar) {
    --van-nav-bar-background: #e04242;
   }
   :deep(.van-nav-bar__title){
     --van-nav-bar-title-text-color: #fff;
     --van-nav-bar-title-font-size: 16px;
     --van-font-bold: 400;
   }
   :deep(.van-icon-arrow-left) {
     color:antiquewhite;
   }
   :deep(.van-cell__title){
    text-align: left;
   }

   :deep(.van-cell){
    background-color: #f5e6e1;
   }
   .con{
    background-color: #f5e6e1;
   }
   .list{
    width: 100%;
    margin-left: 0%;
    text-align: left;
   }
   .item_1{
    text-indent: 0.5em;
    color: black;
    font-size: 12px;
    text-align: left;
   }
   :deep(.item_1 img){
    margin-left: -20%;
   }
   .item_2{
    text-align: right;
    color: #777777;
    margin-top: 2%;
    margin-bottom: 3%;
    font-size: 12px;
   }
   .part1{
    position: relative;
    padding-top: 27px;
    padding-bottom: 5px;
   }
   .party{
    position: absolute;
    left: 3%;
    top: 8px;

   }
   .title1{
    text-align: left;
    margin-left: 3%;
   }
   .title1 span{
    background-color: #b60112;
    color: white;
    padding:5px 15px 5px 50px;
    border-radius: 5px;
   }
   </style>