<template>
    <div>
        <van-nav-bar :title="title"  left-arrow @click-left="onClickLeft"/>
          
        <div>
            <van-list  v-model:loading="loading"  :finished="finished"  finished-text="没有更多了"
    @load="getData">
                <van-cell v-for="item in dsrw" :key="item.id" @click="gotoDetail(item)">
                    <template #title>
                        <div class="item_left" v-if="item.image!='' && item.image.length > 31">
                            <van-image
                                width="100%"
                                height="100%"
                            :src="item.image"/>
                        </div>
                        <div class="item_left" v-else>
                            <van-image
                                width="100%"
                                height="100%"
                            :src="require('../../assets/images/head_img.png')"/>
                        </div>
                        <div class="item_right">
                            <!-- <div class="sub_1">{{ item.title }}</div> -->
                            <div class="sub_3" v-html="item.content"></div>
                        </div>
                    </template>
                
                </van-cell>
            </van-list>
        </div>
    </div>
  </template>
  <script>
  // import { ref } from 'vue';
  import {getArchiveList} from '@/api/archives'
  
  import {timestampToDate} from '@/utils/tools'
//   import { useRoute } from 'vue-router'
  export default {
    name: 'DsrwPage',
    data(){
      return {
        dsrw: [],
        loading: false,
        finished: false,
        diyname: 'dsrw',
        page: 1,
        current_page:1,
        last_page: 1
      }
    },
    
    computed: {
        title() {
            let title = '党史人物';
            return title
        }
    },
    created() {
    },
    mounted(){
        //this.getData();
        document.title = this.title;
        const descriptionEle = document.querySelector('meta[name="description"]');
        if (descriptionEle) {
          descriptionEle.content = this.title;
        }
    },
    methods:{
        onClickLeft(){
            history.back();
        },
        formatTime(time){
            return timestampToDate(time);
        },
        gotoDetail(item){
            console.log(item)
            this.$router.push({path: '/main/party/honerdetail', query:{id: item.id}});
        },
        getData(){
            let params = {
                page: this.page,
                size: 10,
                diyname: this.diyname,
                orderway: this.$route.query.orderway
            }
            getArchiveList(params).then((data)=>{
                let info = data.data.data.archivesList
                //this.dsrw = info.data;
                this.dsrw = [...this.dsrw, ...info.data]
                this.loading = false;
                this.current_page = info.current_page;
                if(this.current_page == info.last_page){
                    this.finished = true; // 全部加载完
                }else{
                    this.page = this.page + 1;
                }
            });
      }
    }
  }
  </script>
  <style scoped>

   :deep(.van-nav-bar) {
    --van-nav-bar-background: #e04242;
   }
   
   :deep(.van-nav-bar__title){
     --van-nav-bar-title-text-color: #fff;
     --van-nav-bar-title-font-size: 16px;
     --van-font-bold: 400;
   }
   :deep(.van-icon-arrow-left) {
     color:antiquewhite;
   }
   :deep(.van-cell__title){
    text-align: left;
   }

   .item_name{
    font-weight: 500;
    font-size: 12px;
  }
    .item_left{
        float: left;
        width: 23%;
        padding: 5px;
    }
    .item_right{
        float: left;
        margin-left: 2%;
        margin-top: 3%;
        width: 72%;
        font-size: 12px;
        text-align: left;
        line-height: 15px;
    }
   </style>