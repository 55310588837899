<template>
    <div>
        <div class="header">
            <van-image
                width="100%"
                height="100%"
            :src="require('../../assets/images/ic_area_topical.png')"/>
        </div>
        <div class="today_con">
            <div class="today">
                <div class="t_left">历史上的今天</div>
                <div class="t_right" @click="goTodayDetail()">查看详情</div>
            </div>
            <div>
                <div class="header">
                <van-image
                    width="94%"
                    height="94%"
                :src="require('../../assets/images/szgl_today.png')"/>
                </div>
            </div>
            <div class="today_con">
                <div class="today_title" v-html="today_title">
                
                </div>
                <div class="today_desc" v-html="today_desc">
                    
                </div>
            </div>
        </div>

        <div class="szsc_con">
            <div class="today" style="height: 40px;border-bottom: 1px solid #ddd;">
                <div class="t_left" style="font-weight: 700;">40年来深圳重大改革事项概览</div>
                <div class="t_right" @click="more('szsc')">查看更多</div>
            </div>
            <div class="clear"></div>
            <div  >
                <ul class="list1">
                    <li v-for="(item, index) in szsc" :key="item.id" @click="goDetail(item)">
                        {{ (index+1) + '. ' + item.title }}
                    </li>
                </ul>
            </div>
           
        </div>
        
        <div class="fwzwhyc_con">
            <div class="today" style="height: 40px;border-bottom: 1px solid #ddd;">
                <div class="t_left" style="font-weight: 700;">深圳非物质文化遗产</div>
                <div class="t_right" @click="more('fwzwhyc')">查看更多</div>
            </div>
            <div class="clear"></div>
            <van-list
    v-model:loading="loading"
    :finished="finished"
    finished-text=""
    @load="onLoad"
  >
    <van-cell v-for="item in fwzwhyc" :key="item.id" @click="goDetail(item)">
        <template #title ><div style="text-align: left;">{{ item.title }}</div></template>
        <template #label><div style="text-align: left;">日期：{{ formatTime(item.createtime) }}</div></template>
        <template #value>
            <van-image
                width="80%"
                height="80%"
            :src="item.image"/>
        </template>
    </van-cell>
  </van-list>
            
        </div>

        <div class="szry_con">
            <div class="today" style="height: 40px;border-bottom: 1px solid #ddd;">
                <div class="t_left" style="font-weight: 700;">深圳荣誉</div>
            </div>
            <div class="clear"></div>
            <div>
                <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
                    <van-swipe-item v-for="item in szry" :key="item.id">
                        <van-image
                            width="94%"
                            height="94%"
                        :src="item.image"/>
                    </van-swipe-item>
                </van-swipe>
            </div>
           
        </div>
    </div>

</template>
<script>

import {getSzgl} from '@/api/archives'
import {timestampToDate} from '@/utils/tools'
export default {
    name: "SzglPage",
    data(){
        return {
            today_id: '',
            today_title: '',
            today_desc:  '',
            szsc: [],
            szry: [],
            fwzwhyc: [],
            loading: false,
            finished: true,
        }
    },

    computed: {
        title() {
            let title = '深圳概览';            
            return title
        }
    },
    created() {
        document.title = this.title;
        const descriptionEle = document.querySelector('meta[name="description"]');
        if (descriptionEle) {
          descriptionEle.content = this.title;
        }
    },
    mounted(){
        this.getData();
    },
    methods: {
        formatTime(time){
          return timestampToDate(time);
        },
        more(type){
            this.$router.push('/main/subject/' + type);
        },
        goDetail(item){
            this.$router.push('/archives/' + item.id);
        },
        goTodayDetail(){
            if(this.today_id != ''){
                this.$router.push('/archives/' + this.today_id);
            }
        },
        getData(){
        let params = {}
        getSzgl(params).then((data)=>{
                let info = data.data.data.data;
                // this.list = data.data.data.list;
                let lssdjt = info.lssdjt;
                
                if(lssdjt != null){
                    this.today_title = lssdjt.title;
                    this.today_desc = lssdjt.description;
                }
                // console.log(data.data.data.data)
                this.szsc = info.szsc.splice(0,5);
                this.szry = info.szry;
                this.fwzwhyc = info.fwzwhyc;
        });
      }
    }
}

</script>
<style scoped>
.clear{
    float: clear;
}
.today{
    margin-top: 3%;
}
.t_left{
    float: left;
    margin-left:3%;
    margin-bottom:3%;
}
.t_right{
    float: right;
    margin-right:3%;
    margin-bottom:3%;
    font-size: 15%;
}
.today_title{
    font-weight: 700;
}
.list1{
    text-align: left    ;
    
}
.list1 li{
    border-bottom: 1px solid #ddd;
    padding-left: 3%;
    padding-top: 2%;
    padding-bottom: 2%;
}
.c_right{
    float: right;
}
</style>