<template>
    <div>
      <van-nav-bar :title="title" left-text="" left-arrow @click-left="onClickLeft">
           
      </van-nav-bar>
      <div class="list">
        <van-list
        v-model:loading="loading"
        :finished="finished"
        finished-text=""
        @load="onLoad"
        >
        <van-cell v-for="item in users" :key="item.id">
            <template #title>
                <div class="item_left">
                    <van-image
                        width="100%"
                        height="100%"
                    :src="item.image"/>
                </div>
                <div class="item_right">
                    <div class="sub_1">{{ item.name }}</div>
                    <div class="sub_2">{{ item.job }}</div>
                    <div class="sub_3">{{ item.sub }}</div>
                </div>
            </template>
           
        </van-cell>
        </van-list>
      </div>
    </div>
  
      
    </template>
    
    <script>
  import {getLdcyDetail} from '@/api/archives'
//   import {timestampToDate} from '@/utils/tools'
  // import { showToast } from 'vant';
    import { ref } from 'vue';
    export default {
      name: 'LdcyPage',
      data() {
        return {
            title: '政务公开-领导成员',
            users: []
        }
      },
      setup() {
        //const users = ref([]);
        const loading = ref(false);
        const finished = ref(true);

        return { loading, finished}
      },
      created() {
        document.title = '政务公开-领导成员';
        const descriptionEle = document.querySelector('meta[name="description"]');
        if (descriptionEle) {
          descriptionEle.content = '政务公开-领导成员';
        }
        
      },
      mounted(){
        this.getDetail();
      },
      methods: {
        onLoad(){
            // this.getDetail();
        },
          onClickLeft(){
            history.back();
          },
          getDetail(){
            let params = {              
            }
            getLdcyDetail(params).then((data)=>{
                let info = data.data.data
                this.users = info.data;
             
            });
          }
      },
    }
    </script>
    
    <!-- Add "scoped" attribute to limit CSS to this component only -->
    <style scoped>
    .title{
      padding: 2px 15px;
      margin-top: 10px;
      font-weight: 700;
      font-size: 20px;
      color: #333;
    }
    .list{
        margin-top: 20px;
    }
    .item_left{
        float: left;
        width: 23%;
        padding: 5px;
    }
    .item_right{
        float: left;
        margin-left: 2%;
        margin-top: 3%;
        width: 72%;
        font-size: 12px;
        text-align: left;
        line-height: 15px;
    }

    .sub_1{
        font-weight: 700;
    }
    .sub_2{
        font-weight: 700;
    }
    .sub_3{
        border-top: 2px solid red;
        margin-top: 2%;
    }
    .time_con{
      font-size: 13px;
      padding: 5px 0;
      color: #888;
      text-align: center;
    }
  
    .content{
      padding: 0 15px;
      font-weight: 300;
      font-size: 15px;
    }
    :deep(.content>p>img){
        width: 100%;
    }
    :deep(.van-icon){
        color: black;
    }
    :deep(.van-nav-bar__title){
      font-weight: 500;
    }
    </style>
    