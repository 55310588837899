<template>
    <div>
      <van-nav-bar :title="title"  left-arrow @click-left="onClickLeft"/>
          
      <div>
        <van-list  v-model:loading="loading"  :finished="finished"  finished-text="没有更多了"
    @load="getData">
                <van-grid   :border="true" :column-num="2">
                    <van-grid-item v-for="item in dsjyjd" :key="item.id" :text="item.title" @click="gotoDetail(item)">
                        <van-image
                        :src="item.image"
                        />
                    <span class="item_name">{{item.title}}</span>
                    </van-grid-item>
                </van-grid>
          </van-list>
      </div>
    </div>
  </template>
  <script>
  // import { ref } from 'vue';
  import {getArchiveList} from '@/api/archives'
  
  import {timestampToDate} from '@/utils/tools'
//   import { useRoute } from 'vue-router'
  export default {
    name: 'DsjyjdPage',
    data(){
      return {
        dsjyjd: [],
        loading: false,
        finished: false,
        diyname: 'dsjyjd'
      }
    },
    setup(){
        // const route = useRoute()
        // const diyname = route.path.split('/').pop();
        // //console.log(diyname)
        // return {
        //     diyname
        // }
    },
    computed: {
        title() {
            let title = '党史教育基地';
            
            return title
        }
    },
    created() {
        document.title = this.title;
        const descriptionEle = document.querySelector('meta[name="description"]');
        if (descriptionEle) {
          descriptionEle.content = this.title;
        }
        
      },
    mounted(){
        this.getData();
    },
    methods:{
      onClickLeft(){
        history.back();
      },
      formatTime(time){
        return timestampToDate(time);
      },
      gotoDetail(item){
            this.$router.push({path: '/archives/' + item.id, query:{time: 'no-date'}});
        },
      getData(){
        let params = {
          page: this.$route.query.page ?this.$route.query.page : 1,
        //   size: this.$route.query.size ?this.$route.query.size : 15,
          size: 30,
          diyname: this.diyname,
          orderway: this.$route.query.orderway
        }
        getArchiveList(params).then((data)=>{
                let info = data.data.data.archivesList
                this.dsjyjd = info.data;
                this.loading = false;
                this.finished = true; // 全部加载完
                // this.content = info.content;
                // this.news_title = info.title;
                // this.source = info.user.username;
                // this.time = timestampToDate(info.createtime)
        });
      }
    }
  }
  </script>
  <style scoped>
     :deep(.van-nav-bar) {
    --van-nav-bar-background: #e04242;
   }
   
   :deep(.van-nav-bar__title){
     --van-nav-bar-title-text-color: #fff;
     --van-nav-bar-title-font-size: 16px;
     --van-font-bold: 400;
   }
   :deep(.van-icon-arrow-left) {
     color:antiquewhite;
   }
   :deep(.van-cell__title){
    text-align: left;
   }

   .item_name{
    font-weight: 500;
    font-size: 12px;
  }
   </style>