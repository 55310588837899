<template>
    <div>
        <van-nav-bar :title="title"  left-arrow @click-left="onClickLeft"/>
        <div>
            <div class="search_con">
                <van-search
                v-model="keyword"
                shape="round"
                background="#e6e6e6"
                placeholder="请输入搜索关键词"
                @search="onSearch"
                @clear="clear"
                >
                <template #action>
                    <div @click="onClickButton">搜索</div>
                </template>
                </van-search>
            </div>
            <div>
                <van-tabs  @click-tab="onClickTab" v-model:active="defaultChannel">
                    <van-tab v-for="item in channelList"  :title="item.channel_name" :name="item.channel_id" :key="item.channel_id">{{  }}</van-tab> 
                </van-tabs>
            </div>
            <div>
                <van-list  v-model:loading="loading"  :finished="finished"  finished-text="没有更多了"
  @load="loadData">
                    <van-grid :column-num="3">
                        <van-grid-item @click="gotoBookDetail(item)" v-for="item in bookList" :key="item.id" icon="photo-o" text="">
                            <template #default>
                                <div class="mod3">
                                    <div class="mod3_1">{{ item.title }}</div>
                                    <div class="mod3_2">日期：{{ formatTime(item.publishtime) }}</div>
                                </div>
                            </template>    
                        </van-grid-item>
                    </van-grid>
                </van-list>
            </div>
        </div>
    </div>
</template>
<script>
import {getChannel, getBookList} from '@/api/archives'
import {timestampToDate} from '@/utils/tools'
export default {
    name: "SiteDomainPage",
    
    data(){
        return {
            title: '数字资源',
            keyword: '',
            channelList: [],
            bookList:[],
            index: 1,
            tab: {
                1: 66,
                2: 10,
                3: 77,
                4: 76
            },
            loading: false,
            finished: false,
            page: 1,
            current_page:1,
            last_page: 1,
            defaultChannel: 77
        }
    },
    created() {
        if(this.$route.query.index == undefined){
            this.defaultChannel = this.tab[1];
        }else{
            this.defaultChannel = this.tab[this.$route.query.index];
        }
    },
    computed: {
    },
    mounted(){
        document.title = this.title;
        const descriptionEle = document.querySelector('meta[name="description"]');
        if (descriptionEle) {
        descriptionEle.content = this.title;
        }
        //alert(this.defaultChannel)
        this.getChannelData();
        // alert(this.$route.query.index)
    },
    methods:{
        onClickLeft(){
            history.back();
        },
        formatTime(time){
            return timestampToDate(time);
        },
        onClickTab(item)  {
            console.log(item)
            this.bookList = [];
            this.page = 1;
            this.current_page = 1;
            this.finished = false;
            //this.loadData();
        },
        onSearch(){
            this.page = 1;
            this.bookList = [];
            this.loadData();
        },
        clear(){
            this.page = 1;
            this.bookList = [];
            this.loadData();
        },
        getChannelData() {
            let params = {
                
            }
            getChannel(params).then((data)=>{
                    this.channelList = data.data.data.search_channel;
                    // this.defaultChannel = this.channelList[0].channel_id;
                    // console.log(this.channelList[0])
                    //this.loadData();
            });
        },
        loadData(){
            
            let params ={
                type : this.defaultChannel, 
                search: this.keyword,
                size: 18,
                page: this.page
            }
            getBookList(params).then((data)=>{
                    // this.channelList = data.data.data.search_channel;
                    // this.defaultChannel = this.channelList[0].channel_id;
                    let info = data.data.data.list;
                    this.bookList = [...this.bookList, ...info.data]
                    this.loading = false;
                    this.current_page = info.current_page;
                    if(info.last_page == 0) {
                        this.finished = true;
                        return ;
                    }
                    if(this.current_page == info.last_page){
                        this.finished = true; // 全部加载完
                    }else{
                        this.page = this.page + 1;
                    }
            });
        },
        gotoBookDetail(item){
            this.$router.push({name: 'book_intro', query:{bookid: item.id}});
        }
    }
}
</script>
<style scoped>
  .van-nav-bar{
     background-color: #e04242;
     color: antiquewhite;
   }
   :deep(.van-nav-bar__title){
     color: antiquewhite;
     font-weight: 500;
   }
   :deep(.van-icon-arrow-left){
     color: #fff;
   }
   .mod3_1{
    font-size: 13px;
   }
   .mod3_2{
    font-size: 12px;
    margin-top: 10px;
   }
</style>