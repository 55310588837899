<template>
    <div>
        <div class="mod1">
            <div class="info">
                <p class="bold">文献阅览室开放时间</p>
<p>周一至周五：(周六、周日、国家法定节假日休息)</p>
<p>上午09:30-12:00 下午14:00-17:30</p>
<p><van-icon name="location-o" size="1rem" color="#ee0a24" />地址：深圳市福田区上梅林林丰路2号深圳市方志馆B座4楼</p>
<p><van-icon name="phone-o" size="1rem" color="#ee0a24" />0755-88123104</p>
            </div>
           
            <div class="rule" v-html="rule_text">

            </div>
        </div>
        
        <div class="divider"></div>
        <div class="mod3">
            <div class="title_1">
                方志丛刊
            </div>
            <div class="see_more" @click="more(2)">查看更多</div>
            <div class="list2">
                <van-grid   :border="true" :column-num="3">
                    <van-grid-item v-for="item in fzck" :key="item.id" :text="item.title" @click="gotoIntro(item)">
                        <van-image
                        :src="item.image"
                        />
                    <span class="item_name">{{item.title}}</span>
                    </van-grid-item>
                </van-grid>
            </div>
        </div>
        <div class="divider"></div>
        <div class="mod3">
            <div class="title_1">
                文献史料
            </div>
            <div class="see_more" @click="more(3)">查看更多</div>
            <div class="list2">
                <van-grid   :border="true" :column-num="3">
                    <van-grid-item v-for="item in wxsl" :key="item.id" :text="item.title" @click="gotoIntro(item)">
                        <van-image
                        :src="item.image"
                        />
                    <span class="item_name">{{item.title}}</span>
                    </van-grid-item>
                </van-grid>
            </div>
        </div>
        <div class="divider"></div>
        <div class="mod3">
            <div class="title_1">
                家谱族谱
            </div>
            <div class="see_more" @click="more(4)">查看更多</div>
            <div class="list2">
                <van-grid   :border="true" :column-num="3">
                    <van-grid-item v-for="item in jpzp" :key="item.id" :text="item.title" @click="gotoIntro(item)">
                        <van-image
                        :src="item.image"
                        />
                    <span class="item_name">{{item.title}}</span>
                    </van-grid-item>
                </van-grid>
            </div>
        </div>
        <div class="divider"></div>
        <div class="mod3">
            <div class="title_1">
                古籍善本
            </div>
            <div class="see_more" @click="more(5)">查看更多</div>
            <div class="list2">
                <van-grid   :border="true" :column-num="3">
                    <van-grid-item v-for="item in gjsb" :key="item.id" :text="item.title" @click="gotoIntro(item)">
                        <van-image
                        :src="item.image"
                        />
                    <span class="item_name">{{item.title}}</span>
                    </van-grid-item>
                </van-grid>
            </div>
        </div>
        <div class="divider"></div>
        <div class="mod3">
            <div class="title_1">
                综合
            </div>
            <div class="see_more" @click="more(6)">查看更多</div>
            <div class="list2">
                <van-grid   :border="true" :column-num="3">
                    <van-grid-item v-for="item in zh" :key="item.id" :text="item.title" @click="gotoIntro(item)">
                        <van-image
                        :src="item.image"
                        />
                    <span class="item_name">{{item.title}}</span>
                    </van-grid-item>
                </van-grid>
            </div>
        </div>

    </div>
</template>
<script>
import {getGctj} from '@/api/archives'
import {timestampToDate} from '@/utils/tools'
export default {
    name: "SzdsPage",
    data(){
        return {
            title: '馆藏推荐',
            fzck: [],
            wxsl : [],
            jpzp: [],
            gjsb: [],
            zh: [],
            rule_text: '                    <p class="bold">文献阅览室注意事项</p>\
<p><van-icon name="comment-o" size="1rem" color="#ee0a24" />文明阅读须知：</p>\
<p>第一条 本室提供馆藏文献查阅服务，读者凭本人有效证件入室阅览。</p>\
<p>第二条 请到存包柜寄放私人物品，并妥善保管贵重物品。</p>\
<p>第三条 注意仪表着装，请勿穿背心、拖鞋入室。</p>\
<p>第四条 请勿将有损文献和设施的物品(食品、饮料等)、不透明包(袋)、非本室书刊资料带入本室。</p>\
<p>第五条 请保持室内安静，并将移动通讯设备置于静音状态。</p>\
<p>第六条 室内陈列开架文献，每次限取2册，阅毕请即归还原处。</p>\
<p>第七条 如需查阅馆藏图书文献，可填表交工作人员取阅。每人每日最多查阅图书文献4册。</p>\
<p>第八条 爱护图书，请勿折页、撕页、涂抹、损坏图书。违者停止阅览并赔偿。损坏珍贵文献者，追究其法律责任。</p>\
<p>第九条 馆藏文献未经许可不得带离本室。</p>\
<p>第十条 除特别批准外，室内禁止照相、摄影。</p>',
        }
    },
    created(){
        this.getData();
    },
    mounted(){
        document.title = this.title;
        const descriptionEle = document.querySelector('meta[name="description"]');
        if (descriptionEle) {
            descriptionEle.content = this.title;
        }
    },
    methods: {
        more(type){
            if(type == 1){
                this.$router.push({path: '/archives/list', query:{category: 'nianjiangonggao', title: '年鉴工作通知公告'}});
            }else{
                let diyname = '';
                let title = '馆藏推荐';
                switch(type){
                    case 2:
                        diyname = 'fzck';
                        break;
                    case 3:
                        diyname = 'wxsl';
                        break;
                    case 4:
                        diyname = 'jpzp';
                        break; 
                    case 5:
                        diyname = 'gjsb';
                        break; 
                    case 6:
                        diyname = 'zh';
                        break;      
                }
                    
                this.$router.push({path: '/book/gctj', query:{diyname: diyname, title: title}});
            }
        },
        formatTime(time){
          return timestampToDate(time);
        },
        gotoDetail(item){
            this.$router.push({path: '/archives/' + item.id, query:{time: 'no-date'}});
        },
        getData() {
            let params = {}
            getGctj(params).then((data)=>{
                    let info = data.data.data.data
                    
                    this.fzck = info.fzck;
                    this.wxsl = info.wxsl;
                    this.jpzp = info.jpzp;
                    this.gjsb = info.gjsb;
                    this.zh = info.zh;
                    console.log(info)               
            });
        },
        goDetail(item){
            this.$router.push('/archives/'+ item.id);
        },
        gotoIntro(item){
            this.$router.push({name: 'book_intro', query:{bookid: item.id}});
        },
    }
}
</script>
<style scoped>
.bold{
    font-weight: 700;
}
.info{
    text-align: left;
    padding: 3%;
}
.rule{
    text-align: left;
    padding: 3%;
    height: 10%;
    overflow-y: hidden;
    text-overflow: ellipsis;
}
.divider{
    width: 100%;
    height: 10px;
    background-color: #e6e6e6;
}
.title_1{
    text-align: left;
    margin-left: 3%;
    font-weight: 700;
    font-size: 13px;
    padding: 2% ;
}
.see_more{
    float: right;
   font-size: 12px;
   margin-right: 3%;
   margin-top: -7%;
}

.item_left{
    float: left;
    width: 23%;
    padding: 5px;
}
.item_right{
    float: left;
    margin-left: 2%;
    margin-top: 3%;
    width: 72%;
    font-size: 12px;
    text-align: left;
    line-height: 15px;
}

.sub_1{
    font-weight: 700;
}

.sub_3{
    border-top: 2px solid red;
    margin-top: 2%;
}

.item_name{
    font-weight: 700;
    font-size: 12px;
}
.new_title{
    font-size: 12px;
    width: 90%;
    text-align: center;
    margin-top: -30px;
    color: #e6e6e6;
}
</style>