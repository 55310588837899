<template>
    <div>
        <van-list  v-model:loading="loading"  :finished="finished"  finished-text="没有更多了"
    @load="getData">
            <div class="item" v-for="item in list" :key="item.id" @click="gotoIntro(item)">
                <div class="mod1">
                    <van-image  width="90%" height="90%" :src="item.image"/>
                </div>
                <div class="mod2">
                    <div class="mod3">{{ item.title }}</div>
                    <div class="mod2_2">
                        <div class="mod4">主办 ：{{ author }}</div>
                        <div class="mod5">出版 ：{{ author }}</div>
                        <div class="mod5">出版年 ：{{ formatTime(item.publishtime) }}</div>
                    </div>
                </div>
                <div class="clear"></div>
            </div>
        </van-list>
    </div>
</template>
<script>
  import {getQkList} from '@/api/archives'
  import {timestampToDate} from '@/utils/tools'
export default {
    name: "SzqkPage",
    data(){
        return {
            title: '史志刊物',
            loading: false,
            finished: false,
            list: [],
            author: '中共深圳市委党史文献研究室',
            page: 1,
            current_page:1,
            last_page: 1

        }
    },
    created(){
        // this.getData();
        document.title = this.title;
        const descriptionEle = document.querySelector('meta[name="description"]');
        if (descriptionEle) {
            descriptionEle.content = this.title;
        }
    },
    methods: {
        gotoIntro(item){
            this.$router.push({name: 'book_intro', query:{bookid: item.id}});
        },
        formatTime(time){
            return timestampToDate(time);
        },
        getData() {
            let params = {
                page: this.page,
                size: this.$route.query.size ?this.$route.query.size : 15,
           
            }
            getQkList(params).then((data)=>{
                    let info = data.data.data
                    this.list = [...this.list, ...info.data];
                    this.loading = false;
                    //console.log(info.current_page)
                    this.current_page = info.current_page;
                    if(this.current_page == info.all_page){
                      this.finished = true; // 全部加载完
                    }else{
                      this.page = this.page + 1;
                    }
            });
        }
    }
}
</script>
<style scoped>
.clear{
    clear: both;
}
.item{
    text-align: left;
    height: 140px;
    padding-left: 3%;
    padding-top: 3%;
}
.mod1{
    float: left;
    width: 30%;
}
.mod2{
    float: left;
    width: 70%;
    margin-top: 5%;
}
.mod3{
    font-weight: 500;
}
.mod2_2{
    margin-top: 7%;
    font-size: 13px;
}
</style>