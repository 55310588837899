<template>
  <div>
    <van-nav-bar :title="title"  left-arrow @click-left="onClickLeft"/>
    <div>
        
        <van-tabs  @click-tab="onClickTab" v-model:active="defaultChannel">
            <van-tab v-for="item in channelList"  :title="item.channel_name" :name="item.channel_id" :key="item.channel_id">{{  }}</van-tab> 
        </van-tabs>
        
    </div>    
    <div>
      <van-list  v-model:loading="loading"  :finished="finished"  finished-text="没有更多了"
  @load="getData">
          <van-cell v-for="item in list" :key="item.id" :title="item.title" :to="'/archives/' + item.id">
            <template #title>
                <div v-html="item.title"></div>
            </template>
            <template #label>
              <span  v-show="show_type=='text'">日期：{{ formatTime(item.createtime) }}</span>
            </template>
          </van-cell>
        </van-list>
    </div>
  </div>
</template>
<script>
// import { ref } from 'vue';
import {getArchiveList} from '@/api/archives'

import {timestampToDate} from '@/utils/tools'
export default {
  name: 'ListPage',
  data(){
    return {
        title: '学会',
        list: [],
        loading: false,
        finished: false,
        page: 1,
        current_page:1,
        last_page: 1,
        type: 'xhjg',
        defaultChannel: 'xhjg',
        channelList: [
            {'channel_id': 'xhjg', 'channel_name': '学会机构'},
            {'channel_id': 'xhhd', 'channel_name': '学会活动'}
        ]
    }
  },
  setup(){
      
  },
  computed: {
     
      // title(){
      //   return this.$route.query.title
      // },
      show_type(){
        return this.$route.query.type??'text'
      }
  },
  mounted(){
    document.title = this.title;
    const descriptionEle = document.querySelector('meta[name="description"]');
    if (descriptionEle) {
      descriptionEle.content = this.title;
    }
  },
  methods:{
    onClickLeft(){
      history.back();
    },
    formatTime(time){
      return timestampToDate(time);
    },
    onClickTab(index, title)  {
        console.log(index, title)
        console.log(this.defaultChannel)
        this.list = [];
        this.page = 1;
        this.current_page = 1;
        this.finished = false;
        // this.getData();
    },
    getData(){
        let params = {
            page: this.page,
            size: 15,
            diyname: this.defaultChannel,
            //type: this.$route.query.type
        }
        getArchiveList(params).then((data)=>{
            let info = data.data.data.archivesList
              
            this.list = [...this.list, ...info.data]
            this.loading = false;
            this.current_page = info.current_page;
            if(this.current_page == info.last_page){
                this.finished = true; // 全部加载完
            }else{
                if(info.last_page == 0) {
                    this.finished = true;
                }else{
                    this.page = this.page + 1;
                }
            }
        });
    }
  }
}
</script>
<style scoped>
.van-nav-bar{
   background-color: #e04242;
 }
 :deep(.van-nav-bar__title){
   color:white;
   font-weight: 500;
 }
 :deep(.van-icon-arrow-left){
   color:antiquewhite;
 }

 :deep(.van-cell__title){
    text-align: left;
   }
 </style>