<template>
    <div>
      <van-nav-bar :title="title"  left-arrow @click-left="onClickLeft"/>
      <div>
        <van-search
        v-model="kw"
        show-action
        placeholder="请输入搜索关键词"
        @search="onSearch"
        @clear="onClear"
      >
        <template #action>
          <div @click="onClickButton">搜索</div>
        </template>
      </van-search>
      </div>    
      <div>
        <van-list  v-model:loading="loading"  :finished="finished"  finished-text="没有更多了"
    @load="getData">
            <div v-for="item in list" :key="item.id" class="part1" @click="toplay(item)">
                <van-image class="" width="95%"  :src="item.image"/>
<div class="p2">{{ item.title }}</div>
            </div>
            
          </van-list>
      </div>
    </div>
  </template>
  <script>
  // import { ref } from 'vue';
  import {getArchiveList} from '@/api/archives'
 
  import {timestampToDate} from '@/utils/tools'
  export default {
    name: 'GclListPage',
    data(){
      return {
        list: [],
        loading: false,
        finished: false,
        kw: '',
        page: 1,
        current_page:1,
        last_page: 1,
      }
    },
    setup(){
        
    },
    computed: {
        diyname(){
            return this.$route.query.diyname??'gcljlp'
        },
        title(){
            if(this.diyname == 'gcljlp'){
                return '自然村落纪录片'
            }else{
                return '自然村落图片'
            }
          
        },
        show_type(){
          return this.$route.query.type??'text'
        }
    },
    mounted(){
      document.title = this.title;
      const descriptionEle = document.querySelector('meta[name="description"]');
      if (descriptionEle) {
        descriptionEle.content = this.title;
      }
    },
    methods:{
      onClickButton(){
        this.getData();
      },
      toplay(item){
        if(this.diyname == 'gcljlp'){
          this.$router.push({path: '/site/player', query:{id: item.id, title: item.title}});
        }
        
      },
      onClickLeft(){
        history.back();
      },
      formatTime(time){
        return timestampToDate(time);
      },
      onSearch(){
        this.list = [];
        this.page = 1;
        this.getData();
      },
      onClear(){
        this.list = [];
        this.page = 1;
        this.getData();
      },
      getData(){
        let params = {
          page: this.page,
          size: 15,
          diyname: this.diyname,
          orderway: 'desc',
          kw: this.kw,
        }
        getArchiveList(params).then((data)=>{
                let info = data.data.data.archivesList
                this.list = info.data;
                this.loading = false;
                this.current_page = info.current_page;
                if(info.last_page == 0) {
                    this.finished = true;
                    return ;
                }
                if(this.current_page == info.last_page){
                    this.finished = true; // 全部加载完
                }else{
                    this.page = this.page + 1;
                }
        });
      }
    }
  }
  </script>
  <style scoped>

   :deep(.van-nav-bar) {
    --van-nav-bar-background: #e04242;
   }
   
   :deep(.van-nav-bar__title){
     --van-nav-bar-title-text-color: #fff;
     --van-nav-bar-title-font-size: 16px;
     --van-font-bold: 400;
   }
   :deep(.van-icon-arrow-left) {
     color:antiquewhite;
   }
   :deep(.van-cell__title){
    text-align: left;
   }
   :deep(.van-image__img){
    border-radius: 3px;
   }
   .part1{
    margin:5%;

   }
   .p2{
    text-align: left;
    font-size: 12px;
    margin-left: 3%;
   }
   </style>