import Index from '@/components/Index.vue'
import archives_list from '@/archives/List.vue'
import archives_detail from '@/archives/detail'
import book_donate from '@/book/donate.vue'
import book_donatelist from '@/book/donatelist.vue'
import government from '@/government/index.vue'
import government_danweijianjie from '@/government/danweijianjie.vue'
import government_jgsz from '@/government/jgsz.vue'
import government_ldcy from '@/government/ldcy.vue'
import government_zdxxfk from '@/government/zdxxfk.vue'
import government_fagui from '@/government/list.vue'
import government_ysjs from '@/government/list.vue'
import government_zlxz from '@/government/list.vue'
import government_zb from '@/government/list.vue'
import government_szbry from '@/government/list.vue'
import government_bianminfuwu from '@/government/bianminfuwu.vue'
import government_zjdc from '@/government/zjdc.vue'
import main_subject_szgl from '@/main/subject/szgl.vue'
import main_subject_szsc from '@/main/subject/szsc.vue'
import main_subject_fwzwhyc from '@/main/subject/fwzwhyc.vue'
import main_pavilion_detail from '@/main/pavilion/detail'
import main_subject_szqk from '@/main/subject/szqk.vue'
import site_domain from '@/site/domain.vue'
import book_intro from '@/book/intro.vue'
import book_reader from '@/book/reader.vue'
import main_subject_gcl from '@/main/subject/gcl.vue'
import main_subject_gclml from '@/main/subject/gclml.vue'
import main_subject_gcljlp from '@/main/subject/gcljlp.vue'

import main_subject_szds from '@/main/subject/szds.vue'
import book_dszz from '@/book/dszz.vue'
import book_dfz from '@/book/dfz.vue'
import book_gctj from '@/book/gctj.vue'
import main_party_edu from '@/main/party/edu.vue'
import main_subject_szdfz from '@/main/subject/szdfz.vue';
import main_subject_nianjian from '@/main/subject/sznianjian.vue';
import book_sznianjian from '@/book/sznianjian.vue'
import main_subject_gctj from '@/main/subject/gctj.vue'
import timeline from '@/site/timeline.vue'
import video from '@/site/video.vue'
import video_detail from '@/site/player.vue'
import images from '@/site/images.vue'
import xjpldsdfz from '@/main/subject/xjpldsdfz.vue'
import chairman_list from '@/main/subject/chairman/list'
import search from '@/site/search.vue'
import appoint from '@/main/pavilion/appoint'
import appoint_query from '@/main/pavilion/appoint/query'
import main_party_honer from '@/main/party/honor.vue'
import main_party_honerdetail from '@/main/party/honordetail.vue'
import { createRouter, createWebHistory } from 'vue-router'
import main_subject_land from '@/main/subject/land.vue'
import xh_list from '@/site/xh.vue'
//路由数组
const routes = [
    {path: "/", name: "index",component: Index, children: []},
    {path: "/archives/:id", name: "archives_detail",component: archives_detail, children: []},
    {path: "/archives/list", name: "archives/list",component: archives_list, children: []},
    {path: "/book/donate", name: "book/donate",component: book_donate, children: []},
    {path: "/book/donateList", name: "book/donateList",component: book_donatelist, children: []},
    {path: "/government", name: "government",component: government, children: []},
    {path: "/government/danweijianjie", name: "government_danweijianjie",component: government_danweijianjie, children: []},
    {path: "/government/jgsz", name: "government_jgsz",component: government_jgsz, children: []},
    {path: "/government/ldcy", name: "government_ldcy",component: government_ldcy, children: []},
    {path: "/government/zdxxfk", name: "government_zdxxfk",component: government_zdxxfk, children: []},
    {path: "/government/fagui", name: "government_fagui",component: government_fagui, children: []},
    {path: "/government/ysjs", name: "government_ysjs",component: government_ysjs, children: []},
    {path: "/government/zlxz", name: "government_zlxz",component: government_zlxz, children: []},
    {path: "/government/zb", name: "government_zb",component: government_zb, children: []},
    {path: "/government/bianminfuwu", name: "government_bianminfuwu",component: government_bianminfuwu, children: []},
    {path: "/government/zjdc", name: "government_zjdc",component: government_zjdc, children: []},
    {path: "/government/szbry", name: "government_szbry",component: government_szbry, children: []},

    {path: "/main/subject/szgl", name: "main_subject_szgl",component: main_subject_szgl, children: []},
    {path: "/main/subject/szsc", name: "main_subject_szsc",component: main_subject_szsc, children: []},
    {path: "/main/subject/fwzwhyc", name: "main_subject_fwzwhyc",component: main_subject_fwzwhyc, children: []},
    {path: "/main/subject/szqk", name: "main_subject_szqk",component: main_subject_szqk, children: []},
    {path: "/main/pavilion/detail", name: "main_pavilion_detail",component: main_pavilion_detail, children: []},
    {path: "/main/subject/gcl", name: "main_subject_gcl",component: main_subject_gcl, children: []},
    {path: "/main/subject/gclml", name: "main_subject_gclml",component: main_subject_gclml, children: []},
    {path: "/main/subject/gcljlp", name: "main_subject_gcljlp",component: main_subject_gcljlp, children: []},
    {path: "/main/subject/szds", name: "main_subject_szds",component: main_subject_szds, children: []},
    {path: "/book/intro", name: "book_intro",component: book_intro, children: []},
    {path: "/book/reader", name: "book_reader",component: book_reader, children: []},
    {path: "/site/domain", name: "site_domain",component: site_domain, children: []},
    {path: "/book/dszz", name: "book_dszz",component: book_dszz, children: []},
    {path: "/book/dfz", name: "book_dfz",component: book_dfz, children: []},
    {path: "/book/gctj", name: "book_gctj",component: book_gctj, children: []},
    {path: "/main/party/edu", name: "main_party_edu",component: main_party_edu, children: []},
    {path: "/main/subject/szdfz", name: "main_subject_szdfz",component: main_subject_szdfz, children: []},
    {path: "/main/subject/land", name: "main_subject_land",component: main_subject_land, children: []},
	{path: "/main/subject/sznianjian", name: "main_subject_nianjian",component: main_subject_nianjian, children: []},
    {path: "/book/sznianjian", name: "book_sznianjian",component: book_sznianjian, children: []},
    {path: "/main/subject/gctj", name: "main_subject_gctj",component: main_subject_gctj, children: []},
    {path: "/site/timeline", name: "timeline",component: timeline, children: []},
    {path: "/site/video", name: "video",component: video, children: []},
    {path: "/site/player", name: "video_detail",component: video_detail, children: []},
    {path: "/site/images", name: "images",component: images, children: []},
    {path: "/main/subject/xjpldsdfz", name: "xjpldsdfz",component: xjpldsdfz, children: []},
    {path: "/main/subject/chairman/list", name: "chairman_list",component: chairman_list, children: []},
    {path: "/site/search", name: "search",component: search, children: []},
    {path: "/main/pavilion/appoint", name: "appoint",component: appoint, children: []},
    {path: "/main/pavilion/appoint/query", name: "appoint_query",component: appoint_query, children: []},
    {path: "/main/party/honer", name: "main_party_honer",component: main_party_honer, children: []},
    {path: "/main/party/honerdetail", name: "main_party_honerdetail",component: main_party_honerdetail, children: []},
    {path: "/site/xh", name: "xh_list",component: xh_list, children: []},
]
//路由对象
const router = createRouter({
          history: createWebHistory(process.env.BASE_URL),
          routes //上面的路由数组
})
//导出路由对象，在main.js中引用
export default router