<template>
    <div>
        <van-nav-bar :title="title"  left-arrow @click-left="onClickLeft"/>
        <div class="mod1">
            <div class="mod1_1">
                <div class="mod1_1_1">{{ info.title }}</div>
                <div class="mod1_1_2">出版年 ：{{ formatTime(info.publishtime) }}</div>
            </div>
            <div class="mod1_2">
                <van-image  width="70%" height="70%" :src="info.image"/>
            </div>
        </div>
        <div class="mod2">
            <div class="mod2_1">
                内容简介
            </div>
            <div class="mod2_2" v-html="info.content">
            </div>
        </div>
        <div class="mod3">
            <van-button class="toread" @click="toRead()" square type="danger" size="large" color="#e04242">
                <template #default>
                    <van-icon width="50%" size="40" name="browsing-history-o" />
                    <span>立即阅读</span>
                </template>
            </van-button>
        </div>
    </div>
</template>
<script>
import {getBookinfo} from '@/api/archives'
import {timestampToDate} from '@/utils/tools'
export default {
    name: "BookIntroPage",
    data(){
        return {
            title: '书籍简介',
            id: '',
            info: '',

        }
    },
    created() {
        
    },
    mounted(){
        this.id = this.$route.query.bookid;
        this.getData();
    },
    methods:{
        toRead(){
            this.$router.push({name: 'book_reader', query:{title: this.info.title, bookid: this.info.book_id}});
        },
        onClickLeft(){
            history.back();
        },
        formatTime(time){
            return timestampToDate(time);
        },
        getData() {
            let params = {
                id: this.id
            }
            getBookinfo(params).then((data)=>{
                    this.info = data.data.data.archivesInfo
                    //this.list = info;
                    //console.log(info)
            });
        }
    }
}
</script>
<style scoped>
  .van-nav-bar{
     background-color: #7d7c82;
   }
   :deep(.van-nav-bar__title){
     color: #fff;
     font-weight: 500;
   }
   :deep(.van-icon-arrow-left){
     color: #fff;
   }
.mod1{
    height: 150px;
    background-color: #7d7c82;
    padding-top: 5%;
    padding-bottom: 5%;
}
.mod1_1{
    float: left;
    width: 40%;
    margin-left: 3%;
    margin-top: 5%;
    color: #fff;
}

.mod1_2{
    float: right;
    width: 40%;
}
.mod1_1_2{
    margin-top: 25%;
    font-size: 13px;
}
.mod2{
    margin-top: 5%;
    margin-left: 5%;
}
.mod2_1{
    text-align: left;
    font-weight: 500;
}
.mod2_2{
    text-align: left;
}
.mod3{
    position: absolute;
    bottom: 0px;
    width: 100%;
}
:deep(.van-button__text){
    display: flex;
    align-items: center;
}
</style>

