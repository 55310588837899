<template>
  <div>
    <van-nav-bar title="标题" left-text="" left-arrow @click-left="onClickLeft">
      <template #title>
        <van-image width="100"  height="20" :src="require('../assets/images/header.png')"/>
      </template>     
    </van-nav-bar>
    <div class="detail">
      <div class="title" v-html="news_title">
      </div>
      <div class="time_con" v-if="show_time">
        <span class="time">日期:{{ time }}</span>
        <span> 信息来源:{{ source }}</span>
      </div>
      <div class="content" v-html="content">     
      </div>
    </div>
  </div>

    
  </template>
  
  <script>
import {getArchiveDetail} from '@/api/archives'
import {timestampToDate} from '@/utils/tools'
// import { showToast } from 'vant';
  export default {
    name: 'ListPage',
    data() {
      return {
        news_title: '',
        content: '',
        time: '',
        source: '',
        show_time : true
      }
    },
    props: {
      msg: String
    },
    computed: {
        id() {
        return this.$route.params.id
        },
    },
    created() {
      document.title = '新闻列表';
      const descriptionEle = document.querySelector('meta[name="description"]');
      if (descriptionEle) {
        descriptionEle.content = '新闻列表';
      }
      if(this.$route.query.time == 'no-date'){
        this.show_time = false;
      }
      
    },
    mounted(){
      this.getDetail();
    },
    methods: {
        onClickLeft(){
          history.back();
        },
        getDetail(){
          //showToast({message: this.id})
          getArchiveDetail(this.id).then((data)=>{
              let info = data.data.data.archivesInfo
              this.content = info.content;
              this.news_title = info.title;
              if(info.title != ''){
                document.title = info.title;
              }
              
              if(info.user != undefined){
                this.source = info.user.username;
              }
              
              this.time = timestampToDate(info.publishtime)
          });
        }
    },
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
  .title{
    padding: 2px 15px;
    margin-top: 10px;
    font-weight: 700;
    font-size: 20px;
    color: #333;
  }
  .time_con{
    font-size: 15px;
    padding: 5px 0;
    color: #888;
    text-align: center;
  }
.detail{
  width: 100%;
}
  .content{
    padding: 0 15px;
    word-wrap: break-word;
    width: 90%;
    text-align: left;
  }
  :deep(.van-icon){
    color: black;
  }
  :deep(p img){
    width: 100%;
  }
  </style>
  