<template>
    <div>
      <van-nav-bar :title="title"  left-arrow @click-left="onClickLeft"/>
      <div class="header">
        <div class="h_left">{{ title }}</div>
        <van-field
        v-model="fieldValue"
        is-link
        readonly
        label="月份"
        placeholder="选择月份"
        @click="showPicker = true"
        />
        <van-popup v-model:show="showPicker" round position="bottom">
        <van-picker v-model="month"
            :columns="columns"
            @cancel="showPicker = false"
            @confirm="onConfirm"
        />
        </van-popup>
      </div>    
      <div>
        <van-list  v-model:loading="loading"  :finished="finished"  finished-text="没有更多了"
    @load="getData">
            <van-cell v-for="item in list" :key="item.id" :title="item.title" :to="'/archives/' + item.id">
              <template #label>
                <span  v-show="show_type=='text'">日期：{{ formatTime(item.createtime) }}</span>
              </template>
            </van-cell>
          </van-list>
      </div>
    </div>
  </template>
  <script>
  // import { ref } from 'vue';
  import {getArchiveList} from '@/api/archives'
  
  import {timestampToDate} from '@/utils/tools'
  export default {
    name: 'ListPage',
    data(){
      return {
        list: [],
        loading: false,
        finished: false,
        title: '历史上的今天',
        showPicker: false,
        fieldValue: '',
        columns: [
            { text: '一月', value: '1' },
            { text: '二月', value: '2' },
            { text: '三月', value: '3' },
            { text: '四月', value: '4' },
            { text: '五月', value: '5' },
            { text: '五月', value: '6' },
            { text: '六月', value: '7' },
            { text: '七月', value: '8' },
            { text: '九月', value: '9' },
            { text: '十月', value: '10' },
            { text: '十一月', value: '11' },
            { text: '十二月', value: '12' },
        ]
        }
    },
    setup(){
        
    },
    computed: {
       
        show_type(){
          return this.$route.query.type??'text'
        },
        month(){
          var currentDate = new Date();
          var currentMonth = currentDate.getMonth() + 1;          
          return [currentMonth]
        }
    },
    mounted(){
      document.title = this.title;
      const descriptionEle = document.querySelector('meta[name="description"]');
      if (descriptionEle) {
        descriptionEle.content = this.title;
      }
      // this.getData();
      //console.log(this.type)
    },
    methods:{
        onConfirm({ selectedOptions }) {
            this.showPicker = false;
            this.fieldValue = selectedOptions[0].text;
            this.month = [selectedOptions[0].value];
            this.getData();
            //console.log(selectedOptions)
        },
      onClickLeft(){
        history.back();
      },
      formatTime(time){
        return timestampToDate(time);
      },
      getData(){
        let params = {
          page: 1,
          size: 15,
          diyname: 'lssdjt_' + this.month,
          type: this.$route.query.type
        }
        getArchiveList(params).then((data)=>{
                let info = data.data.data.archivesList
                this.list = info.data;
                this.loading = false;
                this.finished = true; // 全部加载完
                // this.content = info.content;
                // this.news_title = info.title;
                // this.source = info.user.username;
                // this.time = timestampToDate(info.createtime)
        });
      }
    }
  }
  </script>
  <style scoped>
  .van-nav-bar{
     background-color: #fff;
   }
   :deep(.van-nav-bar__title){
     color: black;
   }
   :deep(.van-icon-arrow-left){
     color:black;
   }
   :deep(.van-cell__title){
  text-align: left;
  }
   .h_left{
    float: left;
    margin-left: 15px;
   }
   </style>