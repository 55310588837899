<template>
    <div class="hello">
      <div>
        <ul>
          <li v-for="item in routes" :key="item.id">
            <router-link :to="item.path">{{ item.name }}</router-link>
          </li>
        </ul>
    
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'IndexPage',
    data(){
      return {
        routes:[],  
        routes1:[
          {id:1, path: '/', name:'首页'},
          {id:101, path: '/archives/list?type=text&category=dawanqudt&title=湾区动态', name:'首页-湾区动态'},
          {id:102, path: '/archives/list?type=text&category=szgm&title=深圳概貌 ', name:'首页-深圳概貌'},
          {id:103, path: 'site/domain?index=1', name:'首页-数字资源搜索1'},
          {id:104, path: 'site/domain?index=2', name:'首页-数字资源搜索2'},
          {id:105, path: 'site/domain?index=3', name:'首页-数字资源搜索3'},
          {id:106, path: 'site/domain?index=4', name:'首页-数字资源搜索4'},
          {id:107, path: 'site/timeline', name:'首页-历史上的今天'},
          {id:108, path: 'site/video', name:'首页-影像深圳-视频'},
          {id:109, path: 'site/images', name:'首页-影像深圳-图片'},
          {id:110, path: '/main/subject/xjpldsdfz', name:'首页-专题专栏1'},
          {id:111, path: '/site/search', name:'首页-搜索'},
          {id:112, path: '/main/pavilion/appoint', name:'首页-预约'},
          
          {id:2, path: '/archives/41223', name:'新闻详情'},
          {id:3, path: '/archives/list?type=text&category=gzdt&title=史志动态', name:'史志动态'},
          {id:4, path: '/archives/list?type=text&category=szyaowen&title=深圳新闻', name:'深圳新闻'},
          {id:5, path: '/archives/list?type=text&category=gnyw&title=国内新闻', name:'国内新闻'},
          {id:6, path: '/archives/list?type=text&category=gjxw&title=国际新闻', name:'国际新闻'},
          {id:7, path: '/book/donate', name:'征集募捐'},
          {id:8, path: '/government', name:'政务公开'},
          {id:9, path: '/main/subject/szgl', name:'深圳概览'},
          {id:91, path: '/main/subject/szds', name:'深圳党史文献'},
          {id:92, path: '/main/subject/szdfz', name:'深圳地方志'},
          {id:93, path: '/main/subject/sznianjian', name:'深圳年鉴'},
          {id:10, path: '/main/pavilion/detail?id=1', name:'数字党史馆'},
          {id:11, path: '/main/pavilion/detail?id=2', name:'数字方志馆'},
          {id:12, path: '/site/domain', name:'数字资源'},
          {id:13, path: '/main/subject/szqk', name:'史志刊物'},
          {id:14, path: '/main/subject/gcl', name:'深圳自然村落'},
          {id:15, path: '/main/subject/gctj', name:'馆藏推荐'},
          {id:16, path: '/main/subject/land', name:'深圳地情知多少'},
        ]
      }
    },
    props: {
      msg: String
    },
    methods: {
      gotoList(){
        this.$router.push('/hello');
      }
    },
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>

  </style>
  