<template>
    <div>
      <van-nav-bar :title="title" left-text="" left-arrow @click-left="onClickLeft">
           
      </van-nav-bar>
      <div class="list2">
            <van-grid   :border="true" :column-num="3">
                <van-grid-item v-for="item in dszz" :key="item.id" :text="item.title" @click="gotoIntro(item)">
                    <van-image
                    :src="item.image"
                    />
                <span class="item_name">{{item.title}}</span>
                </van-grid-item>
            </van-grid>
        </div>
    </div>
</template>
    
    <script>
  import {getArchiveList} from '@/api/archives'
//   import {timestampToDate} from '@/utils/tools'
  // import { showToast } from 'vant';
    export default {
      name: 'DszzListPage',
      data() {
        return {
            title: '党史著作',
            dszz: [],
            page: 1,
            total: 0,
            current_page: 1,
            last_page: 1
        }
      },
      created() {
        document.title = '党史著作';
        const descriptionEle = document.querySelector('meta[name="description"]');
        if (descriptionEle) {
          descriptionEle.content = '党史著作';
        }
        
      },
      mounted(){
        this.getList();
      },
      methods: {
          onClickLeft(){
            history.back();
          },
          gotoIntro(item){
            this.$router.push({name: 'book_intro', query:{bookid: item.id}});
          },
          getList(){
            //showToast({message: this.id})
            let params = {
                diyname: 'dszz',
                orderway: 'desc',
                size: 12,
                page: 1
            }
            getArchiveList(params).then((data)=>{
                
                let info = data.data.data.archivesList ;
                this.dszz = info.data
                this.total = info.total;
                this.last_page = info.last_page;
                this.current_page = info.current_page;
                // console.log(info.archivesList);
            });
          }
      },
    }
    </script>
    
    <!-- Add "scoped" attribute to limit CSS to this component only -->
    <style scoped>
    .title{
      padding: 2px 15px;
      margin-top: 10px;
      font-weight: 700;
      font-size: 20px;
      color: #333;
    }
   
  .item_name{
    font-weight: 500;
    font-size: 12px;
  }
  :deep(.van-nav-bar__title){
    font-weight: 500;
  }

  :deep(.van-icon-arrow-left){
    color: black;
  }
    </style>
    