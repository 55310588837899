<template>
    <div>
      <van-nav-bar :title="title" left-text="" >
            
      </van-nav-bar>
      <div class="grid_con">
        <van-grid   :border="true" :column-num="3" square>
            <van-grid-item v-for="item in grid" :key="item.id" :text="123" @click="clickItem(item)">
                <van-image
                :src="item.image"
                />
              <span class="item_name">{{item.title}}</span>
            </van-grid-item>
        </van-grid>
      </div>
    </div>
</template>
<script>
import {getGridList} from '@/api/archives'
// import {timestampToDate} from '@/utils/tools'
// import { showToast } from 'vant';
  export default {
    name: 'ListPage',
    data() {
      return {
        title: '政务公开',
        grid: []
      }
    },
    props: {
      msg: String
    },
    computed: {
       
    },
    created() {
      document.title = this.title;
      const descriptionEle = document.querySelector('meta[name="description"]');
      if (descriptionEle) {
        descriptionEle.content = this.title;
      }
      
    },
    mounted(){
      this.getGrid();
    },
    methods: {
        getGrid(){
          getGridList().then((data)=>{
            this.grid = data.data.data.list;
          });
        },
        clickItem(item){
          if(item.diyname == 'xzsp' || item.diyname == 'hdly'){
            window.location.href = item.jump_url;
          }
         
          this.$router.push('/government/' + item.diyname);
        }
    },
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
  .grid_con{
    margin-top: 10px;
  }
  .item_name{
    margin-top: 5px;
    font-size: 13px;
  }

  .title{
    padding: 2px 15px;
    margin-top: 10px;
    font-weight: 700;
    font-size: 20px;
    color: #333;
  }
  .time_con{
    font-size: 15px;
    padding: 5px 0;
    color: #888;
    text-align: center;
  }

  .content{
    padding: 0 15px;
  }
  :deep(.van-nav-bar__title){
    font-weight: 500;
  }
  </style>
  