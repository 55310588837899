<template>
    <div>
        <van-nav-bar :title="title"  left-arrow @click-left="onClickLeft"/>
        <div>
            <div class="search_con">
                <van-search
                v-model="keyword"
                shape="round"
                background="#e6e6e6"
                placeholder="请输入搜索关键词"
                @search="onSearch"
                >
                <template #action>
                    <div @click="onClickButton">搜索</div>
                </template>
                </van-search>
            </div>
            <div>
                <van-tabs  @click-tab="onClickTab" v-model:active="defaultChannel">
                    <van-tab v-for="item in channelList"  :title="item.name" :name="item.diyname" :key="item.name">{{  }}</van-tab> 
                </van-tabs>
            </div>
            <div>
                <van-grid :column-num="3">
                    <van-grid-item @click="gotoBookDetail(item)" v-for="item in bookList" :key="item.id" icon="photo-o" text="">
                        <template #default>
                            <div class="mod3">
                                <div class="mod3_3">
                                    <van-image
  width="100"
  height="100"
  :src="item.image"
/>
                                </div>
                                <div class="mod3_1">{{ item.title }}</div>
                                <div class="mod3_2">日期：{{ formatTime(item.publishtime) }}</div>

                            </div>
                        </template>    
                    </van-grid-item>
                </van-grid>
            </div>
        </div>
    </div>
</template>
<script>
import {getGctjChannel, getBookList} from '@/api/archives'
import {timestampToDate} from '@/utils/tools'
export default {
    name: "SiteDomainPage",
    
    data(){
        return {
            title: '馆藏推荐',
            keyword: '',
            channelList: [],
            defaultChannel: 1,
            bookList:[]
        }
    },
    created() {
        
    },
    mounted(){
        this.diyname = this.$route.query.diyname;
        this.defaultChannel = this.diyname;
        this.model = this.$route.query.model;
        this.getChannelData();
        
    },
    methods:{
        onClickLeft(){
            history.back();
        },
        formatTime(time){
            return timestampToDate(time);
        },
        onClickTab(item)  {
            // console.log(item)
            this.defaultChannel = item.name;
            this.diyname = this.defaultChannel
            this.loadData();
        },
        onSearch(){
            this.loadData();
        },
        getChannelData() {
            let params = {
                diyname: 'gctj'
            }
            getGctjChannel(params).then((data)=>{
                    this.channelList = data.data.data.list;
                    // this.defaultChannel = this.channelList[0].id;
                    // console.log(this.channelList)
                    this.loadData();
            });
        },
        loadData(){
            let params ={
                diyname : this.diyname, 
                search: this.keyword,
                size: 20,
                page: 1
            }
            getBookList(params).then((data)=>{
                    // this.channelList = data.data.data.search_channel;
                    // this.defaultChannel = this.channelList[0].channel_id;
                    this.bookList = data.data.data.list.data;
                    console.log(data.data.data)
            });
        },
        gotoBookDetail(item){
            this.$router.push({name: 'book_intro', query:{bookid: item.id}});
        }
    }
}
</script>
<style scoped>
  .van-nav-bar{
     background-color: #e04242;
     color: antiquewhite;
   }
   :deep(.van-nav-bar__title){
     color: antiquewhite;
     font-weight: 500;
   }
   :deep(.van-icon-arrow-left){
     color: #fff;
   }
   .mod3_1{
    font-size: 13px;
   }
   .mod3_2{
    font-size: 12px;
    margin-top: 10px;
   }
</style>