<template>
    <div>
      <van-nav-bar :title="title"  left-arrow @click-left="onClickLeft" right-text="讲解查询"
      @click-right="onClickRight"/>
      <div>
        <van-swipe indicator-color="#df6a74" autoplay="20000" :show-indicators="showIndicators">
                <van-swipe-item>
                    <template #default>
                        <div class="item_1">
                            <van-image class="img" :src="require('../../assets/images/xlzg_1_1.jpg')"/>
                            <p class="item_1_1">| {{ infos1.name }}</p>
                        </div>
                        <div class="item_2">
                            <p class="item_2_1">{{ infos1.name }}</p>
                            <p>开馆时间：<br/>{{ infos1.remark }}</p>
                            <p>展馆地址：<br/>{{ infos1.location }}</p>
                            <p>讲解咨询：<br/>{{ infos1.contact }}</p>
                        </div>
                    </template>
                </van-swipe-item>
                <van-swipe-item>
                    <template #default>
                        <div class="item_1">
                            <van-image class="img" :src="require('../../assets/images/xnzg_img5_03.jpg')"/>
                            <p class="item_1_1">| {{ infos2.name }}</p>
                        </div>
                        <div class="item_2">
                            <p class="item_2_1">{{ infos2.name }}</p>
                            <p>开馆时间：<br/>{{ infos2.remark }}</p>
                            <p>展馆地址：<br/>{{ infos2.location }}</p>
                            <p>讲解咨询：<br/>{{ infos2.contact }}</p>
                        </div>
                    </template>
                </van-swipe-item>

            </van-swipe>
      </div>

        <div class="form_con">
            <div class="top_tips">
                <span>预约讲解信息填写</span>
            </div>
        
            <van-form ref="postForm" @submit="onSubmit">
                <van-field
                v-model="book_donation"
                name="__diyname__"
                rows="1"
                type="hidden"/>
                <van-cell-group inset>
                    <van-cell>
                        <van-field  v-model="select_date1" name="row[order_date]"  is-link  readonly  label="参观日期"  
                placeholder="参观日期" @click="showPicker = true"/>
                        
                    </van-cell>
                    <van-cell>
                        <van-field  v-model="order_time" name="row[order_time]"  is-link  readonly  label="参观时间"  
                placeholder="参观时间" @click="showPicker1 = true"/>
                        
                    </van-cell>
                    <van-cell>
                        <van-field
                    v-model="unit"
                    name="row[unit]"
                    label="单位名称"
                    placeholder="请输入单位名称"
                    :rules="[{ required: true, message: '请输入单位名称' }]"
                    />
                    </van-cell>
                    <van-cell>
                        <van-field
                    v-model="daidui_lingdao"
                    name="row[daidui_lingdao]"
                    label="带队领导"
                    placeholder="请输入带队领导姓名"
                    :rules="[{ required: true, message: '请输入带队领导姓名' }]"
                    />
                    </van-cell>
                    <van-cell>
                        <van-field
                    v-model="lingdao_zhiwu"
                    name="row[lingdao_zhiwu]"
                    label="领导职务"
                    placeholder="请输入领导职务"
                    :rules="[{ required: true, message: '请输入带队领导职务' }]"
                    />
                    </van-cell>
                    <van-cell>
                        <van-field
                    v-model="count"
                    name="row[count]"
                    label="人数"
                    placeholder="请输入人数"
                    :rules="[{ required: true, message: '请输入人数' }]"
                    />
                    </van-cell>
                    <van-cell>
                        <van-field
                    v-model="username"
                    name="row[name]"
                    label="联系人"
                    placeholder="请输入联系人"
                    :rules="[{ required: true, message: '请输入联系人' }]"
                    />
                    </van-cell>
                    <van-cell>
                        <van-field
                        v-model="phone"
                        type="tel"
                        name="row[phone]"
                        label="联系电话"
                        placeholder="请输入联系电话"
                        :rules="[{ required: true, message: '请输入联系电话' }]"
                        />
                        <van-field
                        v-model="code"
                        type="number"
                        name="row[captcha]"
                        label=""
                        placeholder="请输入手机验证码"
                        :rules="[{ required: true, message: '请输入手机验证码' }]"
                        >
                        <template #button>
                            <van-button size="small" :disabled="send_disabled" @click="sendSms()" type="danger">{{ countdownText }}</van-button>
                        </template>
                        </van-field>
                    </van-cell>
                    <van-cell style="text-align: left;">
                        <van-field
                        v-model="attachement"
                        type="hidden"
                        name="row[attachement]"
                        label=""
                        ></van-field>
                        <label>上传预约讲解函：</label>
                        <van-uploader v-model="fileList" :after-read="afterRead" accept="image/*,.pdf,.png,.jpg,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document" max-count="1" max-size="5000000"/>
                    </van-cell>
                    <van-cell title="请勾选要参观的展馆 ">
                        <van-field
                        v-model="hall"
                        type="hidden"
                        name="row[hall]"
                        label=""
                        ></van-field>
                        <van-checkbox-group  v-model="zg_checked" shape="square" @change="selectzg">
                        
                            <van-checkbox name="dangshiguan" checked-color="#ee0a24">党史馆</van-checkbox>
                            <van-checkbox name="fangzhiguan" checked-color="#ee0a24">方志馆</van-checkbox>
                        </van-checkbox-group>
                    </van-cell>
                    
                    
                </van-cell-group>
                <div style="margin: 16px;">
                    <van-button round block type="danger" native-type="submit">
                    立即上传
                    </van-button>
                    <p class="zy">
                        <van-checkbox v-model="readme" name="readme" checked-color="#ee0a24">我已阅读<a class="link" href="https://www.shenzhenshizhi.cn/uploads/%E5%8F%82%E8%A7%82%E6%B3%A8%E6%84%8F%E4%BA%8B%E9%A1%B9.docx">《参观注意事项》</a></van-checkbox>
                    </p>
                </div>
            </van-form>
        </div>
        <div class="rm1">
            <div class="rm1_1">
                <p class="rm1_1_1">免费讲解服务预约说明：</p>
                <p class="rm1_1_1">（温馨提示：自行参观者无需预约，在一楼前台完成登记可自行免费参观展馆）</p>
                <p class="rm1_1_2">1、15人以上团体可通过深圳史志网（www.shenzhenshizhi.cn）预约免费讲解服务，我馆工作人员在收到申请后会致电或发送短信确认讲解具体事宜，<span style="font-weight: bold;">咨询电话为0755-88123104</span>。查看<a href="https://www.shenzhenshizhi.cn/uploads/预约讲解函模板.doc" class="link">《预约讲解函模板》</a></p>
                <p class="rm1_1_2">2、预约讲解函须提供团体名称、联络方式、人数、参观时间、参观场馆等信息，如果是外宾来馆参观，函中请说明陪同单位的名称，电话、人数、语种以及外宾的级别。<br/>
                3、预约讲解名额有限，额满即止。已成功预约讲解的团体请按时间准时到馆参观，若迟到15分钟视为放弃预约讲解。<br/>
                4、请参观者到馆后遵守工作人员的引导，进行实名登记，参观时请注意与他人保持适当距离。</p>
                
            </div>
        </div>

        <van-popup v-model:show="showPicker" round position="bottom">
    <van-date-picker  title="选择日期" 
    v-model="select_date"
    :min-date="minDate"
    :max-date="maxDate"
      @confirm="onConfirm"
  @cancel="showPicker = false"  @change="onChange"/>
</van-popup>
<van-popup v-model:show="showPicker1" round position="bottom">
    <van-picker  title="选择参观时间"  :columns="time_list"  @confirm="onConfirm1"
  @cancel="showPicker1 = false"  @change="onChange1"/>
</van-popup>
    </div>
</template>
<script>
import {upload, sendSmsRequest,postappoint, appoint_info, getVisitTime} from '@/api/archives'
import { showDialog } from 'vant';
import { showToast } from 'vant';

    export default {
        name: 'AppointPage',
        data(){
            return {
                title: '展馆讲解',
                show_result: false,
                showIndicators: false,
                infos1: {},
                infos2: {},
                showPicker1: false,
                showPicker: false,
                times: [],
                time_list: [],
                select_date1: '',
                order_time: '',
                hall: '',
                attachement: '',
                count: 1,
                daidui_lingdao: '',
                lingdao_zhiwu: '',
                unit: '',
                fileList: [],
                send_disabled: false,
                countdown: 0,
                book_donation: '',
                username: '',
                phone: '',
                code: '',
                zg_checked: [],
                readme: 'c',
                select_date: ['2021', '01', '01'],
            }
        },
        setup() {
            const now = new Date();
            const t1 = now.getTime();
            let h = now.getHours();
            let tt = 0;
            if(h < 15){
                tt = t1 + 3600*24*1000
            }else{
                tt = t1 + 2*3600*24*1000
            }
            const now1 = new Date(tt)
            const year1 = now1.getFullYear();
            const month1 = now1.getMonth();
            const day1 = now1.getDate();
            
            const end = new Date(t1 + 15*3600*24*1000);
            const year2 = end.getFullYear();
            const month2 = end.getMonth();
            const day2 = end.getDate();
            
            return {
                
                minDate: new Date(year1, month1, day1),
                maxDate: new Date(year2, month2, day2),
                   
            };
        },
        created() {
            this.getinfo();
            document.title = this.title;
            const descriptionEle = document.querySelector('meta[name="description"]');
            if (descriptionEle) {
                descriptionEle.content = this.title;
            }
        },
        computed:{
            countdownText() {
                if (this.countdown > 0) {
                    return `倒计时 ${this.countdown}s`;
                } else {
                    return "发送验证码";
                }
            }
        },
        methods:{
            onSubmit(values) {
                // if(values.{row == ''){
                //     showToast('请选择要预约的展馆');
                //     return false;
                // }
                postappoint(values).then((data) => {
                    
                    if(data.data.code == 1){
                        showDialog({
                            title: '提交成功',
                            message: data.data.msg,
                            }).then(() => {
                            // on close
                            });
                    }else{
                        showDialog({
                            title: '提交失败',
                            message: data.data.msg,
                            }).then(() => {
                            // on close
                            });
                    }
                });
                console.log(values)
            },
            disableButton(){
                this.send_disabled = true;
            },
            enableButton(){
                this.send_disabled = false;
            },
            startCountdown() {
                this.countdown = 60;
                this.disableButton();
                this.updateCountdown();
            },
            updateCountdown() {
                if (this.countdown > 0) {
                    setTimeout(() => {
                    this.countdown--;
                    this.updateCountdown();
                    }, 1000);
                } else {
                    this.enableButton();
                }
            },
             sendSms(){
                
                
                if(this.phone == ''){
                    showToast('请输入手机号');
                    return false;
                }
                let params = {
                    mobile: this.phone,
                    event: 'showroom'
                }
                sendSmsRequest(params).then((data)=>{
                    
                    if(data.data.msg == 1){
                        this.startCountdown();
                    }else{
                        showToast(data.data.msg)
                    }
                });
            },
            onConfirm(value){
                this.showPicker = false;
                
                let sel_data = value.selectedValues[0]+'-'+value.selectedValues[1]+'-'+value.selectedValues[2];
                this.select_date1 = sel_data;
                
                getVisitTime(sel_data).then((data)=>{
                    let time_list = data.data.data.list;
                   
                    for(let i in time_list){
                        if(time_list[i].can == 1){
                            this.time_list.push({text: time_list[i].can_time, value: time_list[i].id, disabled:false})
                        }else{
                            this.time_list.push({text: time_list[i].can_time, value: time_list[i].id, disabled:true})
                        }
                        
                    }
                    this.showPicker1 = true;
                    // console.log(time_list)
                });
            },
            onChange(){
                this.time_list = [];
                this.order_time = '';
            },
            onConfirm1(value){
                console.log(value.selectedOptions)
                for(let i in this.time_list){
                    if( value.selectedOptions[0].id == this.time_list[i].id && value.selectedOptions[0].disabled == true){
                        this.showPicker1 = false;
                        return false;
                    }
                }
                this.showPicker1 = false;
                this.order_time = value.selectedOptions[0].text
                
            },
            onChange1(){

            },
            onClickLeft(){
                history.back();
            },
            onClickRight(){
                this.$router.push('/main/pavilion/appoint/query');
            },
            getinfo(){
                appoint_info().then((data)=>{
                    
                    this.infos1 = data.data.data.list[0]
                    this.infos2 = data.data.data.list[1]
                    
                });
            },
            afterRead(file){
                upload(file).then((data) => {
                    if(data.data.code == 1){
                        this.attachement = data.data.data.full_url;
                        console.log(this.attachement)
                    }else{
                        showToast('error');
                    }
                    console.log(data)
                });
            },
            selectzg(value){
                value;
                let c = [];
                let a =this.zg_checked[0];
                let b =this.zg_checked[1];
                if(a != undefined){
                    c.push(a);
                }
                if(b != undefined){
                    c.push(b);
                }
                if(c.length>0){
                    this.hall = c.join(',')
                    console.log(c)
                }
                console.log(c.length)
            }
            
        }

    }
</script>
<style scoped>
.form_con{
    margin-top: 5px;
}
.top_tips{
    text-align: left;
    font-size: 13px;
    font-weight: 700;
    margin-left: 25px;
}
:deep(.van-field__label:before) {
    content: '*';
    display: inline-block;
    margin-right: 4px;
    color: #f00;
  }
  .item_1{
    position: relative;
  }
  .item_1_1{
    position: absolute;
    bottom:-8px;
    left: 7%;
    color: white;
    font-size: 14px;
  }
 .img{
    height: 230px;
    border-radius: 5px;
    width: 94%;
    margin-left: 1%;
    margin-top: 15px;
  }
  .item_2{
    text-align: left;
    font-size: 13px;
    width:94%;
    margin-left: 3%;
  }
  .item_2_1{
    font-size: 15px;
    font-weight: 700;
  }
  .zy{
    font-size: 14px;
    text-align: center;
    margin-left: 20%;
  }
  .rm1{
    border-top: 5px solid #f4f4f4;
    background-color: white;
  }
  .rm1_1{
    padding-top: 5px;
    text-align: left;
    width: 94%;
    margin-left: 3%;
  }
  .rm1_1_1{
    font-weight: 700;
  }
  .rm1_1_2{
    font-size: 14px;
  }
  .link{
    color: #ef5d24;
  }
  :deep(.van-nav-bar__text){
    color: #575757;
  }
</style>
