<template>
    <div>
        <div class="mod1">
            <van-image  width="100%" height="100%" :src="require('../../assets/images/almanac_topical.png')"/>
        </div>
        <div class="mod2">
            <div class="title_1">
                年鉴工作通知公告
            </div>
            <div class="see_more" @click="more(1)">查看更多</div>
            <div>
                <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
                    <van-swipe-item v-for="item in nianjiangonggao" :key="item.id" @click="goDetail(item)">
                        <van-image
                            width="94%"
                            height="94%"
                        :src="item.image">
                        <template v-slot:default>
                            <p class="new_title" v-html="item.title"></p>
                        </template>
                        </van-image>
                        
                    </van-swipe-item>
                </van-swipe>
            </div>
            <div>
                <van-list
                    v-model:loading="loading"
                    :finished="finished"
                    finished-text=""
                    @load="onLoad"
                >
                    <van-cell v-for="item in nianjiangonggao" :key="item.id" @click="goDetail(item)">
                        <template #title ><div style="text-align: left;" v-html="item.title"></div></template>
                        <template #value>
                            {{ formatTime(item.createtime) }}
                        </template>
                    </van-cell>
                </van-list>
            </div>
        </div>
        <div class="divider"></div>
        <div class="mod3">
            <div class="title_1">
                深圳年鉴
            </div>
            <div class="see_more" @click="more(2)">查看更多</div>
            <div class="list2">
                <van-grid   :border="true" :column-num="3">
                    <van-grid-item v-for="item in sznianjian" :key="item.id" :text="item.title" @click="gotoIntro(item)">
                        <van-image
                        :src="item.image"
                        />
                    <span class="item_name">{{item.title}}</span>
                    </van-grid-item>
                </van-grid>
            </div>
        </div>
    </div>
</template>
<script>
import {getSznianjian} from '@/api/archives'
import {timestampToDate} from '@/utils/tools'
export default {
    name: "SzdsPage",
    data(){
        return {
            title: '深圳年鉴',
            banner: '',
            sznianjian: [],
            nianjiangonggao : [],
            qnianjian: [],
            jdnianjian: []
        }
    },
    created(){
        document.title = this.title;
        const descriptionEle = document.querySelector('meta[name="description"]');
        if (descriptionEle) {
            descriptionEle.content = this.title;
        }
        this.getData();
    },
    methods: {
        more(type){
            if(type == 1){
                this.$router.push({path: '/archives/list', query:{category: 'nianjiangonggao', title: '年鉴工作通知公告'}});
            }else{
                let diyname = '';
                let title = '';
                switch(type){
                    case 2:
                        diyname = 'sznianjian';
                        title = '深圳年鉴';
                        break;
                }
                    
                this.$router.push({path: '/book/sznianjian', query:{diyname: diyname, title: title}});
            }
        },
        formatTime(time){
          return timestampToDate(time);
        },
        gotoDetail(item){
            this.$router.push({path: '/archives/' + item.id, query:{time: 'no-date'}});
        },
        getData() {
            let params = {}
            getSznianjian(params).then((data)=>{
                    let info = data.data.data.data
                    
                    this.banner = info.banner[0].img;
                    this.sznianjian = info.sznianjian;
                    this.nianjiangonggao = info.nianjiangonggao;
                    this.qnianjian = info.qnianjian;
                    this.jdnianjian = info.jdnianjian;
                    
            });
        },
        goDetail(item){
            this.$router.push('/archives/'+ item.id);
        },
        gotoIntro(item){
            this.$router.push({name: 'book_intro', query:{bookid: item.id}});
        },
    }
}
</script>
<style scoped>
.divider{
    width: 100%;
    height: 10px;
    background-color: #e6e6e6;
}
.title_1{
    text-align: left;
    margin-left: 3%;
    font-weight: 700;
    font-size: 13px;
    padding: 2% ;
}
.see_more{
    float: right;
   font-size: 12px;
   margin-right: 3%;
   margin-top: -7%;
}

.item_left{
    float: left;
    width: 23%;
    padding: 5px;
}
.item_right{
    float: left;
    margin-left: 2%;
    margin-top: 3%;
    width: 72%;
    font-size: 12px;
    text-align: left;
    line-height: 15px;
}

.sub_1{
    font-weight: 700;
}

.sub_3{
    border-top: 2px solid red;
    margin-top: 2%;
}

.item_name{
    font-weight: 700;
    font-size: 12px;
}
.new_title{
    font-size: 12px;
    width: 90%;
    text-align: center;
    margin-top: -30px;
    color: #e6e6e6;
}
</style>