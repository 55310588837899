<template>
    <div>
        <van-nav-bar  left-arrow @click-left="onClickLeft">
            <template #title>
                <van-search shape="round" v-model="kw" placeholder="大家都在搜" @search="onSearch">
                    <template #action>
                        <div @click="onSearchButton">搜索</div>
                    </template>
                </van-search>
                
            </template>
            
        </van-nav-bar>
      <div>
        <div class="part1">
            <div class="part1_1">
                <van-field  v-model="select_date"  is-link  readonly  label=""  
                placeholder="时间范围" @click="showPicker = true"/>
<van-popup v-model:show="showPicker" round position="bottom">
    <van-picker  title="时间范围"  :columns="times"  @confirm="onConfirm1"
  @cancel="showPicker = false"  @change="onChange1"/>
</van-popup>

            </div>
            <div class="part1_2">
                <van-field  v-model="select_order"  is-link  readonly  label=""
  placeholder="排序"  @click="showPicker1 = true"/>
<van-popup v-model:show="showPicker1" round position="bottom">
    <van-picker  title="排序"  :columns="sorts"  @confirm="onConfirm2"
  @cancel="showPicker1 = false"  @change="onChange2"/>
</van-popup>
                
            </div>
        </div>
        <div style="clear:both"></div>
        <van-list  v-model:loading="loading"  :finished="finished"  finished-text="没有更多了"
    @load="getData">
            <van-cell v-for="item in list" :key="item.id" @click="detail(item)">
                <template #title><p v-html="item.title"></p></template>
                <template #label>
                <span  v-show="show_type=='text'">日期：{{ formatTime(item.publishtime) }}</span>
              </template>
            </van-cell>
          </van-list>
      </div>
    </div>
  </template>
  <script>
  // import { ref } from 'vue';
  import {search} from '@/api/archives'
  
  import {timestampToDate} from '@/utils/tools'
  export default {
    name: 'ListPage',
    data(){
      return {
        title:'搜索',
        list: [],
        loading: false,
        finished: false,
        current_page:1,
        total: 0,
        page: 1,
        last_page: 1,
        per_page: 10,
        show_type: 'text',
        kw:'',
        showPicker: false,
        select_date: '',
        showPicker1: false,
        select_order: '',
        times:[
            {text:'不限', value: 0},
            {text:'一天内', value: 1},
            {text:'一周内', value: 7},
            {text:'一月内', value: 30}
        ],
        sorts:[
            {text:'降序', value: 'desc'},
            {text:'升序', value: 'asc'}
        ],
        dayrange: 0,
        orderway: 'desc'
      }
    },
    setup(){
        
    },
    computed: {
        keyword(){
          return this.$route.query.keyword
        }
    },
    mounted(){
      document.title = this.title;
      const descriptionEle = document.querySelector('meta[name="description"]');
      if (descriptionEle) {
        descriptionEle.content = this.title;
      }
        this.kw = this.keyword
      // this.getData();
      //console.log(this.type)
    },
    methods:{
      onClickLeft(){
        history.back();
      },
      formatTime(time){
        return timestampToDate(time);
      },
      onSearchButton(){
        this.getData();
      },
      onSearch(){
        this.getData();
      },
      onConfirm1(options){
        this.showPicker = false;
        this.dayrange = options.selectedOptions[0].value;
        this.select_date = options.selectedOptions[0].text;
        this.page = 1;
        this.list = [];
        this.getData();
        // this.select_date = options
      },
      onConfirm2(options){
        this.showPicker1 = false;
        if(this.orderway != options.selectedOptions[0].value) {
          this.orderway = options.selectedOptions[0].value;
          this.select_order = options.selectedOptions[0].text;
          this.page = 1;
          this.list = [];
          this.getData();
        }
        
      },
      onChange1(){
      },
      onChange2(){
        
      },
      getData(){
        if(this.kw == undefined){
          this.loading = false;
          return ;
        }
        let params = {
          page: this.page,
          size: 15,
          kw: this.kw,
          dayrange: this.dayrange,
          orderway: this.orderway
        }
        search(params).then((data)=>{   
            let info = data.data.data.list
                
                this.list = [...this.list, ...info.data];
                
                for(let i in info.data){
                    let t = info.data[i].title;
                    let n = '<span class="red-keyword">' + this.kw + '</span>' 
                    let new_title = t.replace(this.kw, n);
                    info.data[i].title = new_title
                }
                this.total = info.total;
                this.last_page = info.last_page;
                this.per_page = info.per_page;
                this.loading = false;
                this.current_page = info.current_page;
                if(this.current_page == info.last_page){
                  this.finished = true; // 全部加载完
                }else{
                  this.page = this.page + 1;
                }
        });
      },
      detail(item){
        // console.log(item)
        if(item.model_id == 1){
            this.$router.push({path: '/archives/'+ item.id});
        }else if(item.model_id == 9){ // book
            this.$router.push({path: '/book/intro', query:{bookid: item.id}});
        }else if(item.model_id == 6){ // player
            this.$router.push({path: '/site/player', query:{id: item.id}});
        }
        
      }
    }
  }
  </script>
<style scoped>
.van-nav-bar{
    background-color: #fff;
  }
  :deep(.van-nav-bar__title){
    color:antiquewhite;
  }
  :deep(.van-icon-arrow-left){
    color: #000;
  }
  :deep(.van-cell__title){
  text-align: left;
  }
.part1_1{
    float: left;
    width: 45%;
}
.part1_2{
    border-left: 1px solid #423c3c;
    float: left;
    width: 45%;
}
:deep(.red-keyword) {
  color: red;
}
   </style>