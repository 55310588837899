export default {
    host: {
        dev: 'http://www.shenzhenshizhi.me/',
        pro: 'https://www.shenzhenshizhi.cn/'
    },
    baseUrl: {
        dev: 'http://www.shenzhenshizhi.me/addons/cms/',
        pro: 'https://www.shenzhenshizhi.cn/addons/cms/'
    },
    videoUrl: {
        dev: 'https://minio.shenzhenshizhi.cn',
        pro: 'https://minio.shenzhenshizhi.cn',
    }
}