<template>
    <div>
    <van-nav-bar :title="title"  left-arrow @click-left="onClickLeft"/>
      <div class="detail">
        
        <div class="time_con">
            <div>便民服务</div>
          <span> 来源:{{ source }}</span>
        </div>
        <div class="list">
          <van-list
          v-model:loading="loading"
          :finished="finished"
          finished-text=""
          @load="onLoad"
          >
          <van-cell v-for="item in first" :key="item.id" @click="goto(item)">
              <template #title>
                  <div class="item_left">
                      <van-image
                          width="100%"
                          height="100%"
                      :src="item.image"/>
                  </div>
                  <div class="item_right">
                      <div class="sub_1">{{ item.title }}</div>
                      <div class="sub_2">{{ item.url }}</div>
                  </div>
              </template>
            
          </van-cell>
          </van-list>
        </div>
        <div class="fenge">
          <van-image  width="100%" height="100%" :src="require('../assets/images/bianmin_banner.png')"/>
        </div>
        <div class="list">
          <van-list
          v-model:loading="loading"
          :finished="finished"
          finished-text=""
          @load="onLoad"
          >
          <van-cell v-for="item in second" :key="item.id" @click="goto(item)">
              <template #title>
                  <div class="item_left">
                      <van-image
                          width="100%"
                          height="100%"
                      :src="item.image"/>
                  </div>
                  <div class="item_right">
                      <div class="sub_4">{{ item.title }}</div>
                      
                  </div>
              </template>
            
          </van-cell>
          </van-list>
        </div>

      </div>
    </div>
  
      
    </template>
    
    <script>
  import {getBianminfuwu} from '@/api/archives'
//   import {timestampToDate} from '@/utils/tools'
  // import { showToast } from 'vant';
    export default {
      name: 'ListPage',
      data() {
        return {
           
            title: '政务公开-便民服务',
            source: '中共深圳市委党史文献研究室',
            first: [],
            second: [],
            loading: false,
            finished: true,
        }
      },
      props: {
        msg: String
      },
      computed: {
          id() {
          return this.$route.params.id
          },
      },
      created() {
        document.title = this.title;
        const descriptionEle = document.querySelector('meta[name="description"]');
        if (descriptionEle) {
          descriptionEle.content = this.title;
        }
        
      },
      mounted(){
        this.getDetail();
      },
      methods: {
          onClickLeft(){
            history.back();
          },
          getDetail(){
            //showToast({message: this.id})
            getBianminfuwu().then((data)=>{

                let info = data.data.data.list
                this.first = info.first
                this.second = info.second
                console.log(info)
            });
          },
          goto(item){
            location.href= item.url
          }
      },
    }
    </script>
    
    <!-- Add "scoped" attribute to limit CSS to this component only -->
    <style scoped>
    .fenge{
      width: 92%;
      margin-left: 4%;

    }
    .time_con{
      margin-top: 25px;
    }
    .time_con span{
      font-size: 13px;
    }
   .van-nav-bar{
     background-color: #fff;
   }
   :deep(.van-nav-bar__title){
    font-weight: 500;
    color: #000;
  }
   :deep(.van-icon-arrow-left){
     color: #000;
   }
   :deep(.van-cell__title){
    text-align: left;
    border: 1px solid #ddd;
   }
   .list{
        margin-top: 20px;
    }
 
    .item_left{
        float: left;
        width: 13%;
        height: 50px;
        padding: 5px;
    }
    .item_right{
        float: left;
        margin-left: 2%;
        margin-top: 3%;
        width: 72%;
        font-size: 12px;
        text-align: left;
        line-height: 15px;
    }

    .sub_1{
        font-weight: 700;
    }
    .sub_2{
        font-weight: 700;
    }
    .sub_3{
        border-top: 2px solid red;
        margin-top: 2%;
    }
    .sub_4{
        margin-top: 5%;
    }
    </style>
    