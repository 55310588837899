import {service as request, service_upload as request_upload} from '@/utils/request'
import {getBaseHost} from "@/utils/authorization"

export const getArchiveDetail =  (id) => {
    return request({
        url: 'wxapp.archives/detail?id=' + id,
        method: 'GET'
    })
}
export const getArchiveList =  (params) => {
    return request({
        url: 'wxapp.archives/index?',
        params,
        method: 'GET'
    })
}
//发送短信
export const sendSmsRequest =  (params) => {
    return request({
        baseURL: getBaseHost(),
        url: '/api/sms/send?',
        params,
        method: 'GET'
    })
}
//征集荣誉
export const postbook =  (data) => {
    return request({
        url: 'wxapp.diyform/postbook?',
        data,
        method: 'post',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
    })
}
// 政务公开
export const getGridList =  (params) => {
    return request({
        url: 'wxapp.index/zwgk_list?',
        params,
        method: 'GET'
    })
}
// 政务公开 - 单位简介/机构设置
export const getDanweijianjieDetail = (params) => {
    return request({
        url: 'wxapp.page/detail?',
        params,
        method: 'GET'
    })
}
// 政务公开 - 领导成员
export const getLdcyDetail = (params) => {
    return request({
        url: 'wxapp.special/ldcy',
        params,
        method: 'GET'
    })
}
// 政务公开 - 便民服务
export const getBianminfuwu = (params) => {
    return request({
        url: 'wxapp.index/bmfw',
        params,
        method: 'GET'
    })
}
// 政务公开 - 征集调查
export const  getZjdcList = (params) => {
    return request({
        url: 'wxapp.vote/getlist',
        params,
        method: 'GET'
    })
}
// 深圳概览
export const  getSzgl = (params) => {
    return request({
        url: 'wxapp.special/szgl',
        params,
        method: 'GET'
    })
}
// 数字展馆
export const  getPavilionDetail = (params) => {
    return request({
        url: 'wxapp.showroom/detail',
        params,
        method: 'GET'
    })
}
// 史志刊物
export const getQkList = (params) => {
    return request({
        url: 'wxapp.special/szqk',
        params,
        method: 'GET'
    })
}
// 书籍简介-详情
export const getBookinfo = (params) => {
    return request({
        url: 'wxapp.archives/detail',
        params,
        method: 'GET'
    })
}
// 书籍阅读-获取章节
export const getChapters = (params) => {
    return request({
        url: 'wxapp.ebook/chapters',
        params,
        method: 'GET'
    })
}
// 数字资源-频道列表
export const getChannel = (params) => {
    return request({
        url: 'wxapp.search/channel',
        params,
        method: 'GET'
    })
}
// 馆藏推荐-频道列表
export const getGctjChannel = (params) => {
    return request({
        url: 'wxapp.channel/get_second',
        params,
        method: 'GET'
    })
}
// 数字资源-book列表
export const getBookList = (params) => {
    return request({
        url: 'wxapp.search/index',
        params,
        method: 'GET'
    })
}
export const search = (params) => {
    return request({
        url: 'wxapp.search/zhannei',
        params,
        method: 'GET'
    })
}
// 古村落
export const getGcl = (params) => {
    return request({
        url: 'wxapp.special/gcl',
        params,
        method: 'GET'
    })
}
export const getSzds = (params) => {
    return request({
        url: 'wxapp.special/szds',
        params,
        method: 'GET'
    })
}
export const getSzdfz = (params) => {
    return request({
        url: 'wxapp.special/szdfz',
        params,
        method: 'GET'
    })
}
export const xjpldsdfz = (params) => {
    return request({
        url: 'wxapp.special/xjpldsdfz',
        params,
        method: 'GET'
    })
}
export const getSznianjian = (params) => {
    return request({
        url: 'wxapp.special/sznianjian',
        params,
        method: 'GET'
    })
}
export const getGctj = (params) => {
    return request({
        url: 'wxapp.special/gctj',
        params,
        method: 'GET'
    })
}
export const appoint_info = (params) => {
    return request({
        url: 'wxapp.showroom/appoint_info',
        params,
        method: 'GET'
    })
}
export const getVisitTime= (date) => {
    return request({
        url: 'wxapp.showroom/check_order_time?date=' + date,
        
        method: 'GET'
    })
}
export const postappoint = (data) => {
    return request({
        url: 'wxapp.showroom/postroom?',
        data,
        method: 'post',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
    })
}
export const upload = (data) => {
    return request_upload({
        url: '/addons/minio/index/upload',
        data,
        method: 'post',
        headers: {
            'Content-Type': 'multipart/form-data'
        },
    })
}
export const appoint_query = (params) => {
    return request({
        url: 'wxapp.showroom/query?',
        params,
        method: 'get',
    })
}






