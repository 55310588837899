<template>
    <div>
      <van-nav-bar :title="title"/>
          
      <div>
        <div class="banner_img">
            <van-image
                    width="94%"
                    height="94%"
                :src="banner_img"/>
        </div>
        <div class="con2">
            <van-button color="#d9534f" size="small" round type="success" @click="toVideo(1)">自主导览</van-button>
            <van-button  color="#d9534f" size="small" round type="success" @click="toVideo(2)">导览视频</van-button>
        </div>
        <div class="con3">
            <div class="title">
                {{ info.title }}
            </div>
            <div class="content" v-html="info.description">
            </div>
        </div>
        
      </div>
    </div>
  </template>
<script>
 import {getPavilionDetail} from '@/api/archives'
export default {
    name: 'PavilionDetailPage',
    data(){
      return {
        type: 1,
        title:'',
        info: [],
        banner_img: ''
      }
    },
    created(){
        this.type = this.$route.query.id
       
        if(this.type == 1){
            this.title = '数字展馆-党史馆';
        }else if(this.type == 2){
            this.title = '数字展馆-方志馆';
        }
        this.getData();
        
        document.title = this.title;
        const descriptionEle = document.querySelector('meta[name="description"]');
        if (descriptionEle) {
            descriptionEle.content = this.title;
        }
    },
    computed: {
        
    },
    methods: {
        toVideo(type){
            if(type ==1){
                window.location.href= this.info.jump_url
            }else{
                window.location.href= this.info.daolan_video
            }
            
        },
        getData(){
            let params = {
                id: this.type
            }
            getPavilionDetail(params).then((data)=>{
                // console.log(data.data.data.item)
                this.info = data.data.data.item
                this.banner_img = this.info.image[0];
                    // let info = data.data.data.archivesList
                    // this.list = info.data;
                    // this.loading = false;
                    // this.finished = true; // 全部加载完
                    // this.content = info.content;
                    // this.news_title = info.title;
                    // this.source = info.user.username;
                    // this.time = timestampToDate(info.createtime)
            });
        }
    }
}
</script>
<style scoped>
.con2{
    margin-left: -10%;
    margin-top: 2%;
}
:deep(.van-button){
    padding-left: 10%;
    margin-left: 10%;
    padding-right: 10%;
}
.con3{
    margin-top: 3%;
}
.con3 .title{
    text-align: left;
    margin-left: 3%;
}
.con3 .content{
    padding:0 3%;
    font-weight: 300;
    text-align: left;
}
</style>