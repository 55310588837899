import axios from 'axios'
import {getHost, getBaseHost} from './authorization'

const service = axios.create({
    baseURL: getHost(),
    timeout: 180000,
    headers: {
        'Content-Type': 'application/json',
    },
    handleError: function(e){console.log(e)}
});
const service_upload = axios.create({
    baseURL: getBaseHost(),
    timeout: 180000,
    headers: {
        'Content-Type': 'application/json'
    },
    handleError: function(e){console.log(e)}
});
service.interceptors.request.use(
    config => {
      // 在发送请求之前，在请求头中添加 Authorization
      const token = localStorage.getItem('token');
      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
      }
      return config;
    },
    error => {
      return Promise.reject(error);
    }
  );
export {service, service_upload}

