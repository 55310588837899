<template>
    <div>
      <van-nav-bar :title="title"  left-arrow @click-left="onClickLeft"/>
      <div>
        <van-search
        v-model="kw"
        show-action
        placeholder="请输入搜索关键词"
        @search="onSearch"
      >
        <template #action>
          <div @click="onClickButton">搜索</div>
        </template>
      </van-search>
      </div>
      <div>
            <van-tabs  @click-tab="onClickTab" v-model:active="defaultChannel">
                <van-tab v-for="item in channelList"  :title="item.channel_name" :name="item.channel_id" :key="item.channel_id">{{  }}</van-tab> 
            </van-tabs>
        </div>

      <div style="margin-top: 2%;">
        <van-list  v-model:loading="loading"  :finished="finished"  finished-text="没有更多了"
    @load="getData">
            <div v-for="item in list" :key="item.id" class="part1" >
                <van-image class="" width="95%"  :src="item.image" style="height: 210px;"/>
<div class="p2">{{ item.title }}</div>
            </div>
            
          </van-list>
      </div>
    </div>
  </template>
  <script>
  // import { ref } from 'vue';
  import {getArchiveList} from '@/api/archives'
  
  import {timestampToDate} from '@/utils/tools'
  export default {
    name: 'ListPage',
    data(){
      return {
        list: [],
        loading: false,
        finished: false,
        diyname: 'yxsz_csfz',
        channelList:[
        {channel_id: 'yxsz_csfz', channel_name: '城市发展'},
        {channel_id: 'yxsz_ftrq', channel_name: '民俗风情'}
        ],
        defaultChannel: "yxsz_csfz",
        kw: '',
      }
    },
    setup(){
        
    },
    computed: {
       
        title(){
          return '影像深圳-图片'
        },
        show_type(){
          return this.$route.query.type??'text'
        }
    },
    mounted(){
      document.title = this.title;
      const descriptionEle = document.querySelector('meta[name="description"]');
      if (descriptionEle) {
        descriptionEle.content = this.title;
      }
    },
    methods:{
        onClickTab(item)  {
            console.log(item)
            this.getData();
        },
      onClickButton(){
        this.getData();
      },
      onClickLeft(){
        history.back();
      },
      formatTime(time){
        return timestampToDate(time);
      },
      getData(){
        let params = {
          page: 1,
          size: 15,
          diyname: this.defaultChannel,
          kw: this.kw,
        }
        getArchiveList(params).then((data)=>{
                let info = data.data.data.archivesList
                this.list = info.data;
                this.loading = false;
                this.finished = true; // 全部加载完
                // this.content = info.content;
                // this.news_title = info.title;
                // this.source = info.user.username;
                // this.time = timestampToDate(info.createtime)
        });
      }
    }
  }
  </script>
  <style scoped>
    :deep(.van-nav-bar) {
    --van-nav-bar-background: #e04242;
   }
   :deep(.van-nav-bar__title){
     --van-nav-bar-title-text-color: #fff;
     --van-nav-bar-title-font-size: 16px;
     --van-font-bold: 400;
   }

   :deep(.van-icon-arrow-left){
     color:antiquewhite;
   }
   :deep(.van-cell__title){
    text-align: left;
   }

   :deep(.van-image__img){
    border-radius: 3px;
   }
   .part1 image{
    margin:5%;
    height: 500px;
    margin: a auto;
    text-align: center;
   }
   .p2{
    text-align: center;
    font-size: 12px;
    margin-bottom: 3%;
   }
   </style>