<template>
    <div>
      <van-nav-bar :title="title"  left-arrow @click-left="onClickLeft"/>
          
      <div>
          <van-list
    v-model:loading="loading"
    :finished="finished"
    finished-text=""
    @load="getData"
  >
    <van-cell v-for="item in list" :key="item.id" :to="'/archives/' + item.id">
        <template #title ><div style="text-align: left;">{{ item.title }}</div></template>
        <template #label><div style="text-align: left;">日期：{{ formatTime(item.createtime) }}</div></template>
        <template #value>
            <van-image
                width="80%"
                height="80%"
            :src="item.image"/>
        </template>
    </van-cell>
  </van-list>

      </div>
    </div>
  </template>
  <script>
  // import { ref } from 'vue';
  import {getArchiveList} from '@/api/archives'
  
  import {timestampToDate} from '@/utils/tools'
  import { useRoute } from 'vue-router'
  export default {
    name: 'ZdxxfkPage',
    data(){
      return {
        list: [],
        loading: false,
        finished: false,
      }
    },
    setup(){
        const route = useRoute()
        const diyname = route.path.split('/').pop();
        //console.log(diyname)
        return {
            diyname
        }
    },
    computed: {
        title() {
            let title = '深圳非物质文化遗产';
            
            return title
        }
    },
    created() {
        document.title = this.title;
        const descriptionEle = document.querySelector('meta[name="description"]');
        if (descriptionEle) {
          descriptionEle.content = this.title;
        }
        
      },
    mounted(){
    },
    methods:{
      onClickLeft(){
        history.back();
      },
      formatTime(time){
        return timestampToDate(time);
      },
      getData(){
        let params = {
          page: this.$route.query.page ?this.$route.query.page : 1,
        //   size: this.$route.query.size ?this.$route.query.size : 15,
          size: 200,
          diyname: this.diyname,
          orderway: this.$route.query.orderway
        }
        getArchiveList(params).then((data)=>{
                let info = data.data.data.archivesList
                this.list = info.data;
                this.loading = false;
                this.finished = true; // 全部加载完
                // this.content = info.content;
                // this.news_title = info.title;
                // this.source = info.user.username;
                // this.time = timestampToDate(info.createtime)
        });
      }
    }
  }
  </script>
  <style scoped>

   :deep(.van-nav-bar) {
    --van-nav-bar-background: #e04242;
   }
   :deep(.van-nav-bar__title){
     --van-nav-bar-title-text-color: #fff;
     --van-nav-bar-title-font-size: 16px;
     --van-font-bold: 400;
   }
   :deep(.van-icon-arrow-left) {
     color:antiquewhite;
   }
   :deep(.van-cell__title){
    text-align: left;
   }
   
   </style>