<template>
    <div>
        <div class="mod1">
            <van-image  width="100%" height="100%" :src="require('../../assets/images/party_topical.png')"/>
        </div>
        <div class="mod2">
            <div class="title_1">
                党史工作动态
            </div>
            <div class="see_more" @click="more(1)">查看更多</div>
            <div>
                <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
                    <van-swipe-item v-for="item in dsgonggao" :key="item.id" @click="goDetail(item)">
                        <van-image
                            width="94%"
                            height="200px"
                        :src="item.image">
                        <template v-slot:default>
                            <p class="new_title" v-html="item.title"></p>
                        </template>
                    </van-image>
                    </van-swipe-item>
                </van-swipe>
            </div>
            <div>
                <van-list
                    v-model:loading="loading"
                    :finished="finished"
                    finished-text=""
                    @load="onLoad"
                >
                    <van-cell v-for="item in dsgonggao" :key="item.id" @click="goDetail(item)">
                        <template #title ><div style="text-align: left;" v-html="item.title"></div></template>
                        <template #value>
                            {{ formatTime(item.createtime) }}
                        </template>
                    </van-cell>
                </van-list>
            </div>
        </div>
        <div class="divider"></div>
        <div class="mod3">
            <div class="title_1">
                党史研究
            </div>
            <div class="see_more" @click="more(2)">查看更多</div>
            <div>
                <van-list
                    v-model:loading="loading"
                    :finished="finished"
                    finished-text=""
                    @load="onLoad"
                >
                    <van-cell v-for="item in dangshiyanjiu" :key="item.id" @click="goDetail(item)">
                        <template #title ><div style="text-align: left;">{{ item.title }}</div></template>
                        
                    </van-cell>
                </van-list>
            </div>
        </div>
        <div class="divider"></div>
        <div class="mod4">
            <div class="title_1">
                党史人物
            </div>
            <div class="see_more" @click="more(3)">查看更多</div>
            <div class="list">
                <van-list
                v-model:loading="loading"
                :finished="finished"
                finished-text=""
                @load="onLoad"
                >
                <van-cell v-for="item in dsrw" :key="item.id">
                    <template #title>
                        <div class="item_left" v-if="item.image!=''">
                            <van-image
                                width="100%"
                                height="100%"
                            :src="item.image"/>
                        </div>
                        <div class="item_left" v-else>
                            <van-image
                                width="100%"
                                height="100%"
                            :src="require('../../assets/images/head_img.png')"/>
                        </div>
                        <div class="item_right">
                            <div class="sub_1">{{ item.title }}</div>
                            <div class="sub_3">{{ item.description }}</div>
                        </div>
                    </template>
                
                </van-cell>
                </van-list>
        </div>
        </div>
        <div class="divider"></div>
        <div class="mod5">
            <div class="title_1">
                党史著作
            </div>
            <div class="see_more" @click="more(4)">查看更多</div>
            <div class="list2">
                <van-grid   :border="true" :column-num="3">
                    <van-grid-item v-for="item in dszz" :key="item.id" :text="item.title" @click="gotoIntro(item)">
                        <van-image
                        :src="item.image"
                        />
                    <span class="item_name">{{item.title}}</span>
                    </van-grid-item>
                </van-grid>
            </div>
        </div>
        <div class="divider"></div>
        <div class="mod6">
            <div class="title_1">
                党史教育基地
            </div>
            <div class="see_more" @click="more(6)">查看更多</div>
            <div class="list2">
                <van-grid   :border="true" :column-num="2">
                    <van-grid-item v-for="item in dsjyjd" :key="item.id" :text="item.title" @click="gotoDetail(item)">
                        <van-image
                        :src="item.image"
                        />
                    <span class="item_name">{{item.title}}</span>
                    </van-grid-item>
                </van-grid>
            </div>
        </div>
    </div>
</template>
<script>
import {getSzds} from '@/api/archives'
import {timestampToDate} from '@/utils/tools'
export default {
    name: "SzdsPage",
    data(){
        return {
            title: '深圳党史文献',
            banner: '',
            dsgonggao: [],
            dangshiyanjiu : [],
            dsrw: [],
            dszz: [],
            dsjyjd: [],
        }
    },
    created(){
        document.title = this.title;
        const descriptionEle = document.querySelector('meta[name="description"]');
        if (descriptionEle) {
            descriptionEle.content = this.title;
        }
        this.getData();
    },
    methods: {
        more(type){
            if(type == 1){
                this.$router.push({path: '/archives/list', query:{category: 'dangshigonggao', title: '党史工作动态'}});
            }else if(type == 2){
                this.$router.push({path: '/archives/list', query:{type:'single-text',category: 'dangshiyanjiu', title: '党史研究'}});
            }else if(type == 3){
                this.$router.push({path: '/main/party/honer', query:{type:'single-text',category: 'dsrw', title: '党史人物'}});
            }else if(type == 4){
                this.$router.push({path: '/book/dszz', query:{type:'single-text',category: 'dszz', title: '党史著作'}});
            }else if(type == 6){
                this.$router.push({path: '/main/party/edu', query:{type:'single-text',category: 'dszz', title: '党史教育基地'}});
            }
        },
        formatTime(time){
          return timestampToDate(time);
        },
        gotoDetail(item){
            this.$router.push({path: '/archives/' + item.id, query:{time: 'no-date'}});
        },
        getData() {
            let params = {}
            getSzds(params).then((data)=>{
                    let info = data.data.data.data
                    //this.list = info;
                    // this.gclfbt = info.gclfbt;
                    // this.gcltp = info.gcltp[0].image
                    // this.gcljlp = info.gcljlp.video_file
                    // this.gclminglu = info.gclminglu.splice(0,3);
                    
                    this.banner = info.banner[0].img;
                    this.dsgonggao = info.dangshigonggao;
                    this.dangshiyanjiu = info.dangshiyanjiu;
                    this.dsrw = info.dsrw;
                    this.dszz = info.dszz;
                    this.dsjyjd = info.dsjyjd;
                    console.log(info)
                    console.log(this.banner)
                    
            });
        },
        goDetail(item){
            this.$router.push('/archives/'+ item.id);
        },
        gotoIntro(item){
            this.$router.push({name: 'book_intro', query:{bookid: item.id}});
        },
    }
}
</script>
<style scoped>
.divider{
    width: 100%;
    height: 10px;
    background-color: #e6e6e6;
}
.title_1{
    text-align: left;
    margin-left: 3%;
    font-weight: 700;
    font-size: 13px;
    padding: 2% ;
}
.see_more{
    float: right;
   font-size: 12px;
   margin-right: 3%;
   margin-top: -7%;
}

.item_left{
    float: left;
    width: 23%;
    padding: 5px;
}
.item_right{
    float: left;
    margin-left: 2%;
    margin-top: 3%;
    width: 72%;
    font-size: 12px;
    text-align: left;
    line-height: 15px;
}

.sub_1{
    font-weight: 700;
}

.sub_3{
    border-top: 2px solid red;
    margin-top: 2%;
}

.item_name{
    font-weight: 700;
    font-size: 12px;
}
.new_title{
    font-size: 12px;
    width: 90%;
    text-align: center;
    margin-top: -30px;
    color: #e6e6e6;
}
</style>