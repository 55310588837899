<template>
    <div>
      <van-nav-bar :title="title"  left-arrow @click-left="onClickLeft"/>
      <div class="con" style="">
        <div class="con1">
            <videoPlay class="my-video" ref="videoPlayer" v-bind="playerOptions" :playsinline="true">
                    </videoPlay>
        </div>
        
      </div>    
      <div>
        
      </div>
    </div>
  </template>
  <script>
  // import { ref } from 'vue';
  import {getArchiveDetail} from '@/api/archives'
  import config from "@/config"
  import {timestampToDate} from '@/utils/tools'
  import { videoPlay } from 'vue3-video-play'
  import 'vue3-video-play/dist/style.css'

  export default {
    name: 'PlayPage',
    components: { 
        videoPlay
    },
    data(){
      return {
        host: '',
        list: [],
        loading: false,
        finished: false,
        playerOptions: {
                width: '90%', //播放器高度
                height: '50%', //播放器高度
                color: "#0e5f6d", //主题色
                title: "", //视频名称
                muted: false, //静音
                webFullScreen: false,
                speedRate: ["0.75", "1.0", "1.25", "1.5", "2.0"], //播放倍速
                autoPlay: false, //自动播放
                loop: false, //循环播放
                mirror: false, //镜像画面
                ligthOff: false, //关灯模式
                volume: 0.5, //默认音量大小
                control: true, //是否显示控制
                poster: '',
                controlBtns: [
                    "audioTrack",
                    "quality",
                    "speedRate",
                    "volume",
                    "pip",
                    "fullScreen",
                ], //显示所有按钮,
                src: "", // 视频在线地址
            }
      }
    },
    setup(){
        
    },
    computed: {
       
        title(){
          if(this.$route.query.title == ''){
            return '影像深圳-视频'
          }else{
            return this.$route.query.title;
          }
          
        },
        id(){
          return this.$route.query.id
        }
    },
    mounted(){
      let  host = process.env.NODE_ENV === 'development'? config.videoUrl.dev: config.videoUrl.pro;
      this.host = host
      this.getData();
      //console.log(this.type)
    },
    methods:{
      toplay(item){
        this.$router.push({path: 'video/detail', query:{id: item.id}});
      },
      onClickLeft(){
        history.back();
      },
      formatTime(time){
        return timestampToDate(time);
      },
      getData(){

        getArchiveDetail(this.id).then((data)=>{
                let info = data.data.data.archivesInfo
                if(info.video_file.indexOf('http') == -1){
                  this.playerOptions.src  = this.host + info.video_file
                }else{
                  this.playerOptions.src  = info.video_file
                }
                console.log(this.playerOptions.src)
        });
      }
    }
  }
  </script>
  <style scoped>
  .van-nav-bar{
     background-color: #e04242;
   }
   :deep(.van-nav-bar__title){
     --van-nav-bar-title-text-color: #fff;
     --van-nav-bar-title-font-size: 16px;
     --van-font-bold: 400;
   }

   :deep(.van-icon-arrow-left){
     color:antiquewhite;
   }
   :deep(.van-cell__title){
    text-align: left;
   }

   :deep(.van-image__img){
    border-radius: 3px;
   }
   .con{
    width: 100%;
    text-align: center;
   }
   .con1{
    margin-left: 5%;
    margin-top: 3%;
   }
   </style>