<template>
    <div>
        
        <van-nav-bar :title="title"  left-arrow @click-left="onClickLeft">
            <template #right>
                <van-icon name="wap-nav" @click="toggleSidebar"/>
            </template>    
        </van-nav-bar>
        <van-loading v-show="loading"  type="spinner" />
        <div class="pdf-preview">
            <div class="pdf-preview-container">
            <div class="pdf-preview-watermark"></div>
            <pdf 
            ref="pdf" :src="pdfUrl"  :max-height="maxHeight" :max-width="maxWidth" @loaded="loaded()"
            v-for="i in totalPages"
            :key="i" :page="i" 
            style="width: 98%"
            ></pdf>
            </div>
        </div>
        <div class="nav">
            <van-sidebar v-if="showSidebar" style="width: 100%;">
                <el-tree :data="data" :props="defaultProps" @node-click="handleNodeClick" />
            </van-sidebar>
        </div>
        
    </div>
</template>
<script>
import {getChapters} from '@/api/archives'
// import Pdfh5 from "pdfh5";
// import {ref} from  'vue'
import pdf from 'vue3-pdf'
export default {
    name: "BookIntroPage",
    components:{
        pdf
    },
    data(){
        return {
            title: '',
            id: '',
            info: '',
            pdfUrl: '',
            pdfh5: null,
            showSidebar: false,
            data: [],
            defaultProps : {
                children: 'children',
                label: 'name',
            },
            currentPage: 1,
            totalPages: 3,
            maxHeight: 800,
            maxWidth: 600,
            loading: true
        }
    },
    setup(){
        
    },
    created() {
        
    },
    mounted(){
        this.id = this.$route.query.bookid;
        this.title = this.$route.query.title;
        this.getChaptersData();
        // this.pdfh5 = new Pdfh5("#demo", {
        //     pdfurl: "../../static/test.pdf"
        // });
        // //监听完成事件
        // this.pdfh5.on("complete", function (status, msg, time) {
        //     console.log("状态：" + status + "，信息：" + msg + "，耗时：" + time + "毫秒，总页数：" + this.totalNum)
        // })
        
    },
    methods:{
        loaded(event){
            console.log(event)
        },
        buildTree(data, parent_id) {
            const tree = [];
            for (let i = 0; i < data.length; i++) {
                if (data[i].parent_id === parent_id) {
                const node = data[i];
                const children = this.buildTree(data, node.id);
                if (children.length) {
                    node.children = children;
                }
                tree.push(node);
                }
            }
            return tree;
        },
        handleNodeClick(obj, node){
            console.log(obj)
            console.log(node.data.file)
            this.pdfUrl = pdf.createLoadingTask(node.data.file)
            this.toggleSidebar();
            this.pdfUrl.promise.then(pdf => {
                this.totalPages = pdf.numPages
                this.loading = false;
            })
           
        },
        onClickLeft(){
            history.back();
        },
        toggleSidebar(){
            this.showSidebar = !this.showSidebar;
        },
        getChaptersData() {
            let params = {
                book_id: this.id
            }
            getChapters(params).then((data)=>{
                    this.info = data.data.data.list;
                    this.pdfUrl = this.info[0].file;
                    this.data = this.buildTree(this.info, 0);

                    this.loading = false;
                    console.log(this.data)
            });
        }
    }
}
</script>
<style scoped>

.bg{
    background-image: url(../assets/images/imgWatermark.png);
}
  .van-nav-bar{
     background-color: #e04242;
     color: antiquewhite;
   }
   :deep(.van-nav-bar__title){
     color: antiquewhite;
     font-weight: 500;
   }
   :deep(.van-icon-arrow-left){
     color: #fff;
   }
   .pdf-preview {
    overflow: auto;

  display: flex;
  justify-content: center;
  align-items: center;
}

.pdf-preview-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.pdf-preview-watermark {
    background: url(../assets/images/imgWatermark.png);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.3;
    z-index: 10000;
    pointer-events: none;
}
.nav{
    position: absolute;
    top: 45px;
    right: 0px;
    width: 80%;
    height: 100%;
    z-index: 10001;
}
</style>