<template>
    <div>
        <div class="title">{{title}}</div>
        <div class="con">
            <p v-for="item,index in data" :key="index">{{ parseInt(index+1) +'. ' + item }}</p>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'
    export default {
        name:'LandPage',
        data(){
            return{
                title: '深圳地情知多少',
                data: []
            }
        },
        created() {
            this.getData();
        },
        methods: {
            getData(){
                axios.get('/data/land.json')  
                    .then(response => {  
                      this.data = response.data.data;
                      console.log( this.data.data[0])
                    })  
                    .catch(error => {  
                      console.log(error);  
                    });  
            }
        }
    }
</script>

<style>
    .title{
        margin-top: 30px;
        margin-bottom: 10px;
        font-weight: 500;
        text-align: center;
        font-size: 20px;
    }
    .con{
        text-align: left;
        text-indent: 2em;
        padding: 0 1em;
    }
</style>