<template>
    <div>
        <van-nav-bar :title="title"  left-arrow @click-left="onClickLeft"/>
        <div>
            <div class="search_con">
                <van-search
                v-model="keyword"
                shape="round"
                background="#e6e6e6"
                placeholder="请输入搜索关键词"
                @search="onSearch">
                <template #action>
                    <div @click="onClickButton">搜索</div>
                </template>
                </van-search>
            </div>
            
            <div>
                <van-grid :column-num="3">
                    <van-grid-item @click="gotoBookDetail(item)" v-for="item in bookList" :key="item.id" icon="photo-o" text="">
                        
                        <template #default>
                            <van-image
                        :src="item.image"
                        />
                            <div class="mod3">
                                <div class="mod3_1">{{ item.title }}</div>
                                <div class="mod3_2">日期：{{ formatTime(item.publishtime) }}</div>
                            </div>
                        </template>    
                    </van-grid-item>
                </van-grid>
            </div>
        </div>
    </div>
</template>
<script>
import {getArchiveList} from '@/api/archives'
import {timestampToDate} from '@/utils/tools'
export default {
    name: "SznianjianPage",
    
    data(){
        return {
            title: '深圳年鉴',
            keyword: '',
            bookList:[],
            diyname: 'sznianjian',
            current_page: 1,
            total: 0,
            last_page: 1
        }
    },
    created() {
        
    },
    computed: {
    },
    mounted(){
        this.category = this.$route.query.category;
        this.model = this.$route.query.model;
        this.loadData();
    },
    methods:{
        onClickLeft(){
            history.back();
        },
        formatTime(time){
            return timestampToDate(time);
        },
        onSearch(){
            this.loadData();
        },
        loadData(){
            let params ={
                kw: this.keyword,
                diyname: this.diyname,
                size: 18,
                page: 1
            }
            getArchiveList(params).then((data)=>{
                    // this.channelList = data.data.data.search_channel;
                    // this.defaultChannel = this.channelList[0].channel_id;
                    // this.bookList = data.data.data.list.data;
                    let info = data.data.data.archivesList;
                    this.bookList = info.data;
                    this.total = info.total;
                    this.current_page = info.current_page;
                    this.last_page = info.last_page;
                    //console.log(data.data.data.archivesList)
            });
        },
        gotoBookDetail(item){
            this.$router.push({name: 'book_intro', query:{bookid: item.id}});
        }
    }
}
</script>
<style scoped>
  .van-nav-bar{
     background-color: #e04242;
     color: antiquewhite;
   }
   :deep(.van-nav-bar__title){
     color: antiquewhite;
     font-weight: 500;
   }
   :deep(.van-icon-arrow-left){
     color: #fff;
   }
   .mod3_1{
    font-size: 13px;
   }
   .mod3_2{
    font-size: 12px;
    margin-top: 10px;
   }
</style>