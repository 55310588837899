<template>
    <div>
        <div class="mod1">
            <van-image  width="100%" height="100%" :src="require('../../assets/images/village_topical.png')"/>
        </div>
        <div class="mod2">
            <div class="mod4_1">
                自然村落名录
            </div>
            <div class="mod4_2" @click="more(1)">查看更多</div>
            <van-list
                v-model:loading="loading"
                :finished="finished"
                finished-text=""
                @load="onLoad">
                <van-cell v-for="item in gclminglu" :key="item.id" @click="goDetail(item)">
                    <template #title ><div style="text-align: left;">{{ item.title }}</div></template>
                    <template #label><div style="text-align: left;">日期：{{ formatTime(item.createtime) }}</div></template>
                    <template #value>
                        <van-image
                            width="80%"
                            height="80%"
                        :src="item.image"/>
                    </template>
                </van-cell>
            </van-list>

        </div>
        <div class="divider"></div>
        <div class="mod3">
            <div class="mod4_1">
                自然村落纪录片
            </div>
            <div class="mod4_2" @click="more(2)">查看更多</div>
            <div class="mod3_3">
                <video :src="gcljlp" controls >
                    <h2>我们很抱歉，您的浏览器<strong>不支持video标签</strong></h2>
                </video>
            </div>
        </div>
        <div class="divider"></div>
        <div class="mod4">
            <div class="mod4_1">
                自然村落图片
            </div>
            <div class="mod4_2" @click="more(3)">查看更多</div>
            <div>
                <van-image
                    width="94%"
                    height="94%" 
                :src="gcltp"/>
            </div>
        </div>
        <div class="divider"></div>
        <div class="mod5">
            <div class="mod5_1">
                自然村落分布图
            </div>
            <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
                    <van-swipe-item v-for="item in gclfbt" :key="item.id">
                        <van-image
                            width="94%"
                            height="94%" @click="goDetail(item)"
                        :src="item.image"/>
                    </van-swipe-item>
                </van-swipe>
        </div>
    </div>
</template>
<script>
  import {getGcl} from '@/api/archives'
  import {timestampToDate} from '@/utils/tools'
export default {
    name: "GclPage",
    data(){
        return {
            title: '深圳古村落',
            loading: false,
            finished: true,
            gclfbt: [],
            gclminglu: [],
            gcltp: '',
            gcljlp: '',
        }
    },
    created(){
        this.getData();
        document.title = this.title;
        const descriptionEle = document.querySelector('meta[name="description"]');
        if (descriptionEle) {
            descriptionEle.content = this.title;
        }
    },
    methods: {
        more(type){
            if(type == 1){
                this.$router.push('/main/subject/gclml');
            }else if(type == 2) {
                this.$router.push({path: '/main/subject/gcljlp', query:{'diyname': 'gcljlp'}});
            }else if(type == 3) {
                this.$router.push({path: '/main/subject/gcljlp', query:{'diyname': 'gcltp'}});
            }
        },
        formatTime(time){
          return timestampToDate(time);
        },
        getData() {
            let params = {}
            getGcl(params).then((data)=>{
                    let info = data.data.data.data
                    //this.list = info;
                    this.gclfbt = info.gclfbt;
                    this.gcltp = info.gcltp[0].image
                    this.gcljlp = info.gcljlp.video_file
                    this.gclminglu = info.gclminglu.splice(0,3);
                    console.log(this.gcltp)
                    
            });
        },
        goDetail(item){
            this.$router.push('/archives/'+ item.id);
        }
    }
}
</script>
<style scoped>
.divider{
    width: 100%;
    height: 10px;
    background-color: #e6e6e6;
}
.mod3_3{
    width: 100%;
}
.mod3_3 video{
    width: 94%;
}
.mod2,.mod3,.mod4{
    padding-bottom: 10px;
}
.mod4_1{
    text-align: left;
    margin-left: 3%;
    font-weight: 700;
    font-size: 13px;
    padding: 2% ;
}
.mod4_2{
   float: right;
   font-size: 12px;
   margin-right: 3%;
   margin-top: -7%;
}
.mod5_1{
    text-align: left;
    margin-left: 3%;
    font-weight: 700;
    font-size: 13px;
    padding: 2% ;
}
</style>