<template>
    <div>
      <van-nav-bar :title="title" left-text="" left-arrow @click-left="onClickLeft">
           
      </van-nav-bar>
      <div class="detail">
        <div class="title">{{ news_title }} 
        </div>
        <div class="time_con">
          <span> 来源:{{ source }}</span>
        </div>
        <div class="content" v-html="content">     
        </div>
      </div>
    </div>
  
      
    </template>
    
    <script>
  import {getDanweijianjieDetail} from '@/api/archives'
//   import {timestampToDate} from '@/utils/tools'
  // import { showToast } from 'vant';
    export default {
      name: 'DanweijianjiePage',
      data() {
        return {
            title: '政务公开-机构设置',
            content: 'hello',
            source: ''
        }
      },
      created() {
        document.title = '政务公开-机构设置';
        const descriptionEle = document.querySelector('meta[name="description"]');
        if (descriptionEle) {
          descriptionEle.content = '政务公开-机构设置';
        }
        
      },
      mounted(){
        this.getDetail();
      },
      methods: {
          onClickLeft(){
            history.back();
          },
          getDetail(){
            //showToast({message: this.id})
            let params = {
                diyname: 'jgsz'
            }
            getDanweijianjieDetail(params).then((data)=>{
                
                let info = data.data.data.page
                this.source = info.author
                
                this.content = info.content;
                // this.news_title = info.title;
                // this.source = info.user.username;
                // this.time = timestampToDate(info.createtime)
            });
          }
      },
    }
    </script>
    
    <!-- Add "scoped" attribute to limit CSS to this component only -->
    <style scoped>
    .title{
      padding: 2px 15px;
      margin-top: 10px;
      font-weight: 700;
      font-size: 20px;
      color: #333;
    }
    .time_con{
      font-size: 13px;
      padding: 5px 0;
      color: #888;
      text-align: center;
    }
  
    .content{
      padding: 0 15px;
      font-weight: 300;
      font-size: 15px;
    }
    :deep(.content>p>img){
        width: 100%;
    }
    :deep(.van-icon){
        color: black;
    }
    :deep(.van-nav-bar__title){
      font-weight: 500;
    }
    </style>
    