<template>
    <div>
        <van-nav-bar :title="title"  left-arrow @click-left="onClickLeft"/>
          
        <div class="title">
            <div class="p1"><img :src="image"></div>
            <div>{{ news_title }}</div>
        </div>
        <div class="content" v-html="content">
            
        </div>
    </div>
  </template>
  <script>
  // import { ref } from 'vue';
  import {getArchiveDetail} from '@/api/archives'
  
  import {timestampToDate} from '@/utils/tools'
//   import { useRoute } from 'vue-router'
  export default {
    name: 'DsrwPage',
    data(){
      return {
        dsrw: [],
        loading: false,
        finished: false,
        diyname: 'dsrw',
        page: 1,
        current_page:1,
        last_page: 1,
        content: '',
        news_title: '',
        image: ''
      }
    },
    
    computed: {
        id(){
            return this.$route.query.id;
        },
        title() {
            let title = '党史人物';
            return title
        }
    },
    created() {
    },
    mounted(){
        this.getDetail();
        document.title = this.title;
        const descriptionEle = document.querySelector('meta[name="description"]');
        if (descriptionEle) {
          descriptionEle.content = this.title;
        }
    },
    methods:{
        onClickLeft(){
            history.back();
        },
        formatTime(time){
            return timestampToDate(time);
        },
        getDetail(){
          //showToast({message: this.id})
          getArchiveDetail(this.id).then((data)=>{
              let info = data.data.data.archivesInfo
              console.log(info)
              this.content = info.content;
              this.news_title = info.title;
              this.image = info.image;
              if(info.title != ''){
                document.title = info.title;
              }
              
              if(info.user != undefined){
                this.source = info.user.username;
              }
              
              this.time = timestampToDate(info.createtime)
          });
        },
        
    }
  }
  </script>
  <style scoped>
  
   :deep(.van-nav-bar) {
    --van-nav-bar-background: white;
   }
   :deep(.van-nav-bar__title){
     --van-nav-bar-title-text-color: #000;
     --van-nav-bar-title-font-size: 16px;
     --van-font-bold: 400;
   }
   :deep(.van-icon-arrow-left) {
     color: #000;
   }
   :deep(.van-cell__title){
    text-align: left;
   }
   .title{
    border-bottom: 1px solid red;
   }
   .p1 img{
        width: 50%;
   }
.content{
    font-size: 12px;
    letter-spacing: normal;
    line-height: 26px;
    text-align: left;
    padding-left: 3%;
    padding-right: 3%;
}
   </style>