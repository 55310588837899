import { createApp } from 'vue'
import App from './App.vue'

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

import 'vant/lib/index.css';
import { Button } from 'vant'
import { NavBar } from 'vant';
import { Image as VanImage } from 'vant';
import { Toast } from 'vant';
import { List } from 'vant';
import { Cell, CellGroup } from 'vant';
import { Form, Field } from 'vant';
import { RadioGroup, Radio } from 'vant';
import { Grid, GridItem } from 'vant';
import { Swipe, SwipeItem } from 'vant';
import { Tab, Tabs } from 'vant';
import { Icon } from 'vant';
import { Search } from 'vant';
import { Divider } from 'vant';
import { Sidebar, SidebarItem } from 'vant';
import { Loading } from 'vant';
import { TextEllipsis } from 'vant';
import { Popup } from 'vant';
import { Picker } from 'vant';
import { Checkbox, CheckboxGroup } from 'vant';
import { DatePicker } from 'vant';
import { Uploader } from 'vant';
import router from './router'



const app = createApp(App)
app.use(ElementPlus)
app.use(Picker)
app.use(Popup)
app.use(Button)
app.use(NavBar);
app.use(VanImage);
app.use(router)
app.use(Toast)
app.use(List)
app.use(Cell)
app.use(CellGroup)
app.use(Form)
app.use(Field)
app.use(RadioGroup)
app.use(Radio)
app.use(Grid)
app.use(GridItem)
app.use(Swipe)
app.use(SwipeItem)
app.use(Icon)
app.use(Tab)
app.use(Tabs)
app.use(Search)
app.use(Divider)
app.use(Sidebar)
app.use(SidebarItem)
app.use(Loading)
app.use(TextEllipsis)
app.use(Checkbox)
app.use(CheckboxGroup)
app.use(DatePicker)
app.use(Uploader)
app.mount('#app')

