<template>
    <div>
      <van-nav-bar :title="title"  left-arrow @click-left="onClickLeft"/>
          
      <div>
        <van-list  v-model:loading="loading"  :finished="finished"  finished-text="没有更多了"
    @load="getData">
            <van-cell v-for="item in list" :key="item.id" :title="item.title" :to="'/archives/' + item.id">
              <template #label>
                <span>日期：{{ formatTime(item.createtime) }}</span>
              </template>
            </van-cell>
          </van-list>
      </div>
    </div>
  </template>
  <script>
  // import { ref } from 'vue';
  import {getZjdcList} from '@/api/archives'
  
  import {timestampToDate} from '@/utils/tools'
  import { useRoute } from 'vue-router'
  export default {
    name: 'ZdxxfkPage',
    data(){
      return {
        list: [],
        loading: false,
        finished: false,
      }
    },
    setup(){
        const route = useRoute()
        const diyname = route.path.split('/').pop();
        //console.log(diyname)
        return {
            diyname
        }
    },
    computed: {
        title() {
            let title = '政务公开-征集调查';
            
            return title
        }
    },
    created() {
        document.title = this.title;
        const descriptionEle = document.querySelector('meta[name="description"]');
        if (descriptionEle) {
          descriptionEle.content = this.title;
        }
    },
    mounted(){
    },
    methods:{
      onClickLeft(){
        history.back();
      },
      formatTime(time){
        return timestampToDate(time);
      },
      getData(){
        let params = {
          page: this.$route.query.page ?this.$route.query.page : 1,
          size: this.$route.query.size ?this.$route.query.size : 15,
          diyname: this.diyname,
          orderway: this.$route.query.orderway
        }
        getZjdcList(params).then((data)=>{
                
                this.list = data.data.data.list;
                console.log(data.data.data.list)
                this.loading = false;
                this.finished = true; // 全部加载完
                // this.content = info.content;
                // this.news_title = info.title;
                // this.source = info.user.username;
                // this.time = timestampToDate(info.createtime)
        });
      }
    }
  }
  </script>
  <style scoped>
  .van-nav-bar{
     background-color: #fff;
   }
   :deep(.van-nav-bar__title){
    font-weight: 500;
    color: #000;
  }
   :deep(.van-icon-arrow-left){
     color: #000;
   }
   :deep(.van-cell__title){
    text-align: left;
   }
   </style>