<template>
    <div>
      <van-nav-bar :title="title"  left-arrow @click-left="onClickLeft"/>
          
      <div>
        <van-list  v-model:loading="loading"  :finished="finished"  finished-text="没有更多了"
    @load="getData">
            <van-cell v-for="item in list" :key="item.id" :title="item.title" :to="'/archives/' + item.id">
              <template #label>
                <span>日期：{{ formatTime(item.createtime) }}</span>
              </template>
            </van-cell>
          </van-list>
      </div>
    </div>
  </template>
  <script>
  // import { ref } from 'vue';
  import {getArchiveList} from '@/api/archives'
  
  import {timestampToDate} from '@/utils/tools'
  export default {
    name: 'ZdxxfkPage',
    data(){
      return {
        title : '政务公开-通知公告',
        list: [],
        loading: false,
        finished: false,
      }
    },
    setup(){
        
    },
    created() {
        document.title = '政务公开-通知公告';
        const descriptionEle = document.querySelector('meta[name="description"]');
        if (descriptionEle) {
          descriptionEle.content = '政务公开-通知公告';
        }
        
      },
    mounted(){
      // this.getData();
      //console.log(this.type)
    },
    methods:{
      onClickLeft(){
        history.back();
      },
      formatTime(time){
        return timestampToDate(time);
      },
      getData(){
        let params = {
          page: this.$route.query.page ?this.$route.query.page : 1,
          size: this.$route.query.size ?this.$route.query.size : 15,
          diyname: 'zdxxfk',
          orderway: this.$route.query.orderway
        }
        getArchiveList(params).then((data)=>{
                let info = data.data.data.archivesList
                this.list = info.data;
                this.loading = false;
                this.finished = true; // 全部加载完
                // this.content = info.content;
                // this.news_title = info.title;
                // this.source = info.user.username;
                // this.time = timestampToDate(info.createtime)
        });
      }
    }
  }
  </script>
  <style scoped>
  .van-nav-bar{
     background-color: #fff;
   }
   :deep(.van-nav-bar__title){
    font-weight: 500;
    color: #000;
  }
   :deep(.van-icon-arrow-left){
     color: #000;
   }
   :deep(.van-cell__title){
    text-align: left;
   }
   </style>