<template>
    <div>
        <van-nav-bar :title="title"  left-arrow @click-left="onClickLeft" />
        <div class="form_con">
            <div class="top_tips">
                <span>预约讲解信息查询</span>
            </div>
        
            <van-form ref="postForm" @submit="onSubmit">
                <van-field
                v-model="book_donation"
                name="__diyname__"
                rows="1"
                type="hidden"/>
                <van-cell-group inset>
                    <van-cell>
                        <van-field
                    v-model="name"
                    name="name"
                    label="姓名"
                    placeholder="请输入姓名"
                    :rules="[{ required: true, message: '请输入姓名' }]"
                    />
                    </van-cell>
                    <van-cell>
                        <van-field
                        v-model="phone"
                        type="tel"
                        name="phone"
                        label="联系电话"
                        placeholder="请输入联系电话"
                        :rules="[{required: true, message: '请输入联系电话' }]"
                        />
                        <van-field
                        v-model="captcha"
                        type="number"
                        name="captcha"
                        label=""
                        placeholder="请输入手机验证码"
                        :rules="[{required: true, message: '请输入手机验证码' }]"
                        >
                        <template #button>
                            <van-button size="small" @click="sendSms()" type="danger">发送验证码</van-button>
                        </template>
                        </van-field>
                    </van-cell>
                   
                    
                </van-cell-group>
                <div style="margin: 16px;">
                    <van-button round block type="danger" native-type="submit">
                    立即查询
                    </van-button>
                </div>
            </van-form>
        </div>
        <div class="rs">
            <van-cell v-for="item in list" :key="item" :title="item" />
        </div>
    </div>
</template>
<script>
import { sendSmsRequest, appoint_query} from '@/api/archives'
import { showToast } from 'vant';
export default {
    name: 'AppointQuery',
    data(){
        return {
            title: '数字展馆-预约讲解信息查询',
            phone: '',
            name: '',
            captcha: '',
            list: []
        }
    },
    methods: {
        onClickLeft(){
            history.back();
        },
        sendSms(){
            if(this.phone == ''){
                showToast('请输入手机号');
                return false;
            }
            let params = {
                mobile: this.phone,
                event: 'queryshowroom'
            }
            sendSmsRequest(params).then((data)=>{
                showToast(data.data.msg)
            });
        },
        onSubmit(value){
            appoint_query(value).then((data)=>{
                this.list = data.data.data.list
                console.log(data.data.data.list)
            });
        }

    }
}
</script>

<style scoped>
</style>